import React from 'react';
import { Link } from 'react-router-dom';

const DmNavbar = () => {
    return (
        <>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#clients" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="clients">
                <i className="ri-rocket-line"></i>
                <span data-key="t-clients">Clients</span>
                </a>
                <div className="collapse menu-dropdown" id="clients">
                <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                        <Link to="/active-clients" className="nav-link" data-key="t-analytics">Active</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/assigned-clients" className="nav-link" data-key="t-analytics">Assigned</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/pending-clients" className="nav-link" data-key="t-analytics">Pending</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/inactive-clients" className="nav-link" data-key="t-crm">Inactive</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/all-clients" className="nav-link" data-key="t-ecommerce">All</Link>
                    </li>
                </ul>
                </div>
            </li>

            <li className="nav-item">
                <a className="nav-link menu-link" href="#clientrequest" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="clientrequest">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Client Request</span>
                </a>
                <div className="collapse menu-dropdown" id="clientrequest">
                    <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                        <Link to="/client-request-in-sales" className="nav-link" data-key="t-analytics">All Request </Link>
                    </li>
                    
                    </ul>
                </div>
            </li>
        </>
    );
}

export default DmNavbar;
