import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { sendClientRequest } from "../../features/commonSlice/commonSlice";
 

const AssigntoDm = (props) => {
    const { showModal, setShowModal, selectedData } = props;
    const [formData, setFormData] = useState({
        salesteam: [],
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { salesData, status: commonStatus } = useSelector((state) => state.influencer);
    const [salesList, setSalesList] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        if (selectedData) {
            setFormData({
                salesteam: selectedData?.salesteam?.map(steam => ({ label: steam.label, value: steam.value })) || [],
            });
        }
    }, [selectedData]);

    

    useEffect(() => {
        if (commonStatus === 'succeeded') {
            setSalesList(salesData?.data || []);
        }
    }, [commonStatus, salesData]);
 
 

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            const salesTeamData = {
                inf_req_id  : selectedData?.id,
               
            };
            console.log("clientData ==>", salesTeamData);
            dispatch(sendClientRequest(salesTeamData))
                .unwrap()
                .then((result) => {
                    if (result.status) {
                        toast.success(result.message, {
                            onClose: () => {
                                setShowModal(false)
                            },
                        });

                    } else {
                        setLoginError(result.message);
                        setErrorData(result.form_err);
                    }
                })
                .catch((err) => {
                    setLoginError(err.message);
                    setErrorData(err.form_err || []);
                    toast.error(err.message);
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };


    const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
            if (!errorMap[error.param]) {
                errorMap[error.param] = error.msg;
            }
        });
        return errorMap;
    };

    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
    }, [errorData]);

    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Send Request to Digital Marketing Team</Modal.Title>
                </Modal.Header>                              
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0">
                                        <table className="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="w-25 p-3">  Client Name  </td>
                                                    <td>{selectedData?.ClientName} </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">  Product  </td>
                                                    <td>{selectedData?.product} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary" onClick={handleSubmit}>
                        Confirm to Send Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AssigntoDm;
