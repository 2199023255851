import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientInfluencerDataRequestDetails } from "../../features/influencerRequestSlice/influencerRequestSlice";
import moment from "moment";
export const headerData = {
  channel: "Channels",
  followers_subs: "Required Followers / Subscriber",
  languges: "Language",
  video_type: "Video Type"
};

export const headerDataInfluencer = {
  reg_id: "Req Id",
  influencer_name: "Influencer Name",
  channel_name: "Channel Name",
  video_type: "Video Type",
  inf_coste: "Influencer Cost",
  editable_inf_coste: "Editable Influencer Cost",
  video_cat: "Video Category",
  channel_link: "Channel Link",
  subscribe: "Subscribers",
  ave_view: "Average Views",
  languges: "Language"
};

const ClientInfluencerRequestDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedData = location.state;
  const { influencerRequestDatadetails, status } = useSelector(
    (state) => state.requestInfluencerDetails
  );

  const [requestData, setRequestData] = useState(null);
  const [channelData, setChannelData] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    if (selectedData?.id) {
      const payload = {
        inf_req_id: selectedData?.id
      };
      dispatch(clientInfluencerDataRequestDetails(payload));
    }
  }, [dispatch, selectedData]);

  useEffect(() => {
    if (status === "succeeded") {
      setRequestData(influencerRequestDatadetails?.data);
    }
  }, [status, influencerRequestDatadetails]);

  useEffect(() => {
    if (influencerRequestDatadetails?.request_details) {
      const filteredChannelData = Object.entries(
        influencerRequestDatadetails.request_details?.channel_details
      )
        .filter(([key, value]) => key.endsWith("_status") && value === "1")
        .map(([key]) => key.replace("_status", ""))
        .reduce((acc, channel) => {
          acc[channel] =
            influencerRequestDatadetails.request_details?.channel_details[
              channel
            ];
          return acc;
        }, {});
      setChannelData(filteredChannelData);
    }
  }, [influencerRequestDatadetails]);

  const handleCheckboxChange = (rowIndex, isChecked) => {
    setCheckedItems((prev) => ({
      ...prev,
      [rowIndex]: isChecked
    }));
  };

  const handleHeaderCheckboxChange = (isChecked) => {
    const newCheckedItems = {};
    requestData.forEach((data, index) => {
      data.ReqData.forEach((_, ind) => {
        newCheckedItems[`${index}-${ind}`] = isChecked;
      });
    });
    setCheckedItems(newCheckedItems);
    setIsAllChecked(isChecked);
  };

  useEffect( () => {
    console.log("request_details ==>", influencerRequestDatadetails?.request_details);
    
  }, [influencerRequestDatadetails])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Digital Marketing</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li className="breadcrumb-item active">Active Influencers</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card" id="leadsList">
              <div className="card-header border-0">
                <div className="row g-4 align-items-center">
                  <div className="col-sm-auto ms-auto">
                    <div className="hstack gap-2">
                      <button
                        type="button"
                        className="btn btn-info"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                      >
                        <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                        Filters
                      </button>
                      <a
                        href="../sign-up.html"
                        className="btn btn-success add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                        Influencer
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body border-top">
                <p>
                  Client With The Request Id:{" "}
                  <strong>
                    {influencerRequestDatadetails?.request_details?.req_code}
                  </strong>{" "}
                  have requirement of Total Influencer:{" "}
                  <strong>
                    {
                      influencerRequestDatadetails?.request_details
                        ?.no_of_influencer
                    }
                  </strong>
                  , Other details are mentioned below:
                </p>
                {Object.keys(channelData).length > 0 && (
                  <table className="table table-bordered table-striped rounded">
                    <thead>
                      <tr>
                        {Object.keys(headerData).map((key) => (
                          <th key={key}>{headerData[key]}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(channelData).map(([channel, data]) => (
                        <tr key={channel}>
                          <td>{channel}</td>
                          <td>{data?.follower_subscriber?.label || "-"}</td>
                          <td>
                            {data?.language
                              ?.map((lang) => lang.label)
                              .join(", ") || "-"}
                          </td>
                          <td>
                            {data?.video_type
                              ?.map((videoType) => videoType.label)
                              .join(", ") || "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="card-body">
                <div className="col-sm-12 border rounded px-2 py-3 background-secodary  mb-2 bg-light text-dark">
                  <p>
                    <strong>Hello, 😎 Priyanka Rohilla</strong> you have only{" "}
                    <strong>
                      {
                        influencerRequestDatadetails?.request_details
                          ?.active_inf
                      }{" "}
                      Active Influencers
                    </strong>
                    , which is listed below.
                  </p>
                  <hr />
                  <p>
                    <strong>Note:</strong> Please select the required checkboxes
                    below and then click on "Send Selected Influencers" on the
                    top right of your screen to submit a request to the Sales
                    Team for an Influencer Request ID{" "}
                    {influencerRequestDatadetails?.request_details?.req_code}.
                  </p>
                </div>

                {requestData && (
                  <table className="table table-bordered table-striped rounded">
                    <thead>
                      <tr>
                        <th>
                        <input
                            type="checkbox"
                            checked={isAllChecked}
                            onChange={(e) => handleHeaderCheckboxChange(e.target.checked)}
                          />
                        </th>
                        {Object.keys(headerDataInfluencer).map((key) => (
                          <th key={key}>{headerDataInfluencer[key]}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {requestData.map((data, index) =>
                        data?.ReqData?.map((channel, ind) =>
                          channel?.ch_type === "youTube" ? (
                            channel?.video_type?.map((channelVd, indV) => (
                              <>
                                <tr key={indV}>
                                  <td>
                                  <input
                                    type="checkbox"
                                    checked={!!checkedItems[`${index}-${ind}`]}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        `${index}-${ind}`,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  </td>
                                  <td>
                                    {
                                      influencerRequestDatadetails
                                        ?.request_details?.req_code
                                    }
                                  </td>
                                  <td>{data?.name}</td>
                                  <td>{channel?.ch_type}</td>
                                  <td>{channelVd?.label || "-"}</td>
                                  <td>{channel?.budget}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="companyName"
                                      placeholder="Please Enter estimated coste"
                                      // value={formData.companyName}
                                      // onChange={handleChange}
                                    />
                                  </td>
                                  <td>{channelVd?.label || "-"}</td>
                                  <td>{data?.channel_link || "-"}</td>
                                  <td>
                                    {channel?.follower_subscriber?.label || "-"}
                                  </td>
                                  <td>{data?.ave_view || "-"}</td>
                                  <td>
                                    {channel?.language
                                      ?.map((lang) => lang?.label)
                                      .join(", ") || "-"}
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <>
                              <tr key={ind}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={!!checkedItems[`${index}-${ind}`]}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        `${index}-${ind}`,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {
                                    influencerRequestDatadetails
                                      ?.request_details?.req_code
                                  }
                                </td>
                                <td>{data?.name}</td>
                                <td>{channel?.ch_type}</td>
                                <td>{"Cost"}</td>
                                <td>{channel?.budget}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="companyName"
                                    placeholder="Please Enter estimated coste"
                                    // value={formData.companyName}
                                    // onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  {channel?.video_type
                                    ?.map((videoType) => videoType?.label)
                                    .join(", ") || "-"}
                                </td>
                                <td>{data?.channel_link || "-"}</td>
                                <td>
                                  {channel?.follower_subscriber?.label || "-"}
                                </td>
                                <td>{data?.ave_view || "-"}</td>
                                <td>
                                  {channel?.language
                                    ?.map((lang) => lang?.label)
                                    .join(", ") || "-"}
                                </td>
                              </tr>
                            </>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientInfluencerRequestDetails;
