import HeaderBar from "./header/HeaderBar";
import Sidebar from "./sidebar/Sidebar";

import React, { useState } from 'react';

const Common = (props) => {
  const {decodedToken} = props
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <HeaderBar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} decodedToken={decodedToken}/>
      <Sidebar  isOpen={isSidebarOpen} decodedToken={decodedToken}/>
      
    </>
  );
}

export default Common;
