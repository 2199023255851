import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchClientsDetails } from "../../features/clientSlice/clientSlice";

const DetailClients = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [clientsData, setClientsData] = useState([]);
  const selectedData = location.state;

  const { clientDetailsData, status } = useSelector((state) => state.clients);

  useEffect(() => {
    if (selectedData?.id) {
      dispatch(fetchClientsDetails(selectedData.id));
    }
  }, [dispatch, selectedData]);

  useEffect(() => {
    if (status === "succeeded" && clientDetailsData) {
      setClientsData(clientDetailsData?.data[0]);
    }
  }, [status, clientDetailsData]);

  useEffect(() => {
    console.log("clientDetailsData 123456324234:", clientDetailsData?.message);
  }, [clientDetailsData]);

  const renderParsedField = (field) => {
    try {
      const parsedField = JSON.parse(field);
      return parsedField.map((item, index) => (
        <span key={index}>
          {item.label}
          {index < parsedField.length - 1 ? ", " : ""}
        </span>
      ));
    } catch (e) {
      return field;
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-6">
            <h4>Clients Details</h4>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-lg-12 col-xl-12">
        <div className="card">
          <div className="card-body">
            <div className="p-0">
              <table className="table table-striped table-bordered">
                <tbody>
                  {clientDetailsData?.data[0] ? (
                    <>
                      {" "}
                      <tr>
                        <td className="w-25 p-3">Company Name</td>
                        <td>{clientsData?.company_name}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Concern Person Name</td>
                        <td>{clientsData?.concern_person_name}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Phone No</td>
                        <td>{clientsData?.mobile_no}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Email</td>
                        <td>{clientsData?.email}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Categories</td>
                        <td>{renderParsedField(clientsData?.category)}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Languages</td>
                        <td>{renderParsedField(clientsData?.language)}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Budget</td>
                        <td>{clientsData?.budget}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Assigned By</td>
                        <td>{clientsData?.assigned_by_name}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Assigned To</td>
                        <td>{clientsData?.assigned_to_name}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Created At</td>
                        <td>{clientsData?.created_at}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">Updated At</td>
                        <td>{clientsData?.updated_at}</td>
                      </tr>
                      <tr>
                        <td className="w-25 p-3">User Status</td>
                        <td>{clientsData?.user_status}</td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2" className="w-50 p-3 text-center text-danger">
                        {clientDetailsData?.message}
                      </td>
                    </tr>
                  )}
                  {/* Add more fields as needed */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailClients;
