export const formatAmount = (amount) => {
  const formatNumber = (value, unit) => {
    const formatted = (Math.floor(value * 100) / 100).toFixed(2); // Format to two decimals
    return (parseFloat(formatted) % 1 === 0 ? parseFloat(formatted).toFixed(0) : formatted) + unit; // Remove trailing .00
  };

  if (amount >= 10000000) {
    return formatNumber(amount / 10000000, 'Cr');
  } else if (amount >= 100000) {
    return formatNumber(amount / 100000, 'L');
  } else if (amount >= 1000) {
    return formatNumber(amount / 1000, 'K');
  } else {
    return parseFloat(amount) % 1 === 0 ? parseInt(amount) : amount; // Remove trailing .00 if a whole number
  }
};
