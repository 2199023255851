import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';
import commonAxiosInstance from '../../api/commonAxiosInstance';

// Create an async thunk for fetching data
export const fetchData = createAsyncThunk('influencer/fetchDatas', async () => {
  const response = await axiosInstance.get('/sales/dashboard');
  return response.data;
});

export const fetchCategoryData = createAsyncThunk('influencer/fetchCategoryData', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/category');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});

export const fetchLanguageData = createAsyncThunk('influencer/fetchLanguageData', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/language');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});


export const fetchSalesteam = createAsyncThunk('influencer/fetchSalesteam', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/sales_team_list_with_tl');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});


export const fetchDmTeam = createAsyncThunk('influencer/fetchDmTeam', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/dm_team_list_with_tl');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});

 



export const fetchStateData = createAsyncThunk('influencer/fetchStateData', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/state');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});

export const fetchCityData = createAsyncThunk('influencer/fetchCityData', async (formData, { rejectWithValue }) => {
  try {
    const response = await commonAxiosInstance.post('/master/city', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const fetchChannelTypeData = createAsyncThunk('influencer/fetchChannelTypeData', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/channel_type');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});

export const fetchGenreData = createAsyncThunk('influencer/fetchGenreData', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/genre');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});

export const fetchTeamSalesData = createAsyncThunk('influencer/fetchTeamSalesData', async () => {
  try {
    const response = await commonAxiosInstance.get('/master/sales_team_list_with_tl');
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
});

 

export const statusEdit = createAsyncThunk('influencer/statusEdit', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/sales/client_status_update', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const influencerStatusUpdate = createAsyncThunk('influencer/influencerStatusUpdate', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/dm/inf_status_update', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
 
export const ClientAssigntosale = createAsyncThunk('influencer/ClientAssigntosale', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/sales/client_assign_action', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});




export const ClientTempAssign = createAsyncThunk('influencer/ClientTempAssign', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/sales/temp_client_assign_action', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});




export const InfluencerAssign = createAsyncThunk('influencer/InfluencerAssign', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/dm/influencer_assign_action', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const InfluencerTempAssign = createAsyncThunk('influencer/InfluencerTempAssign', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/dm/temp_influencer_assign_action', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const sendClientRequest = createAsyncThunk('influencer/sendClientRequest', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/sales/inf_req_send_to_dm', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});



export const sendDmexeClientRequest = createAsyncThunk('influencer/sendDmexeClientRequest', async (formData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/dm/influencer_req_assign_to_dmexe', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const commonSlice = createSlice({
  name: 'influencer',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchCategoryData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategoryData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categoryData = action.payload;
      })
      .addCase(fetchCategoryData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(statusEdit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(statusEdit.fulfilled, (state, action) => {
        state.statusEdit = 'succeeded';
        state.categoryData = action.payload;
      })
      .addCase(statusEdit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(influencerStatusUpdate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(influencerStatusUpdate.fulfilled, (state, action) => {
        state.influencerStatusUpdate = 'succeeded';
        state.categoryData = action.payload;
      })
      .addCase(influencerStatusUpdate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchLanguageData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLanguageData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.languageData = action.payload;
      })
      .addCase(fetchLanguageData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchSalesteam.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSalesteam.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.salesData = action.payload;
      })
      .addCase(fetchSalesteam.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchDmTeam.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDmTeam.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.salesData = action.payload;
      })
      .addCase(fetchDmTeam.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

 
      
      .addCase(fetchStateData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStateData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stateData = action.payload;
      })
      .addCase(fetchStateData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchCityData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCityData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cityData = action.payload;
      })
      .addCase(fetchCityData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchChannelTypeData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChannelTypeData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.channelTypeData = action.payload;
      })
      .addCase(fetchChannelTypeData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchGenreData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGenreData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.genreData = action.payload;
      })
      .addCase(fetchGenreData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
      .addCase(fetchTeamSalesData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTeamSalesData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.genreData = action.payload;
      })
      .addCase(fetchTeamSalesData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })


      .addCase(ClientTempAssign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(ClientTempAssign.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tempData = action.payload;
      })
      .addCase(ClientTempAssign.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

     

  }
});

export default commonSlice.reducer;
