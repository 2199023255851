import React from "react";

const MainContent = (props) => {
  const { salesData, decodedToken } = props
  return (
    <>

      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0">{decodedToken?.UsrRoleName}</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li class="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card crm-widget">
            <div class="card-body p-0">
              <div class="row row-cols-xxl-4 row-cols-md-3 row-cols-1 g-0">
                {salesData &&
                  salesData.map((item, index) => (
                    <div className="col" key={index}>
                      <div className="py-4 px-3">
                        <h5 className="text-muted text-uppercase fs-13">
                          {item.Titles}
                        </h5>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className="ri-heart-pulse-fill display-6 text-muted"></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h2 className="mb-0">
                              <span className="counter-value" data-target="10">
                                {item.RowCount}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xxl-6 col-md-6">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">
                Performance Report
              </h4>

              <div class="flex-shrink-0">
                <div class="dropdown card-header-dropdown">
                  <a
                    class="text-reset dropdown-btn"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="fw-semibold text-uppercase fs-12">
                      Sort by:{" "}
                    </span>
                    <span class="text-muted">
                      Nov 2023<i class="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">
                      Oct 2023
                    </a>
                    <a class="dropdown-item" href="#">
                      Nov 2023
                    </a>
                    <a class="dropdown-item" href="#">
                      Dec 2023
                    </a>
                    <a class="dropdown-item" href="#">
                      Jan 2023
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pb-0">
              <div
                id="sales-forecast-chart"
                data-colors='["--vz-primary", "--vz-success", "--vz-warning"]'
                class="apex-charts"
                dir="ltr"
              ></div>
            </div>
          </div>
        </div>

        <div class="col-xxl-6">
          <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">
                Monthly Wise Performance
              </h4>
              <div class="flex-shrink-0">
                <div class="dropdown card-header-dropdown">
                  <a
                    class="text-reset dropdown-btn"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="fw-semibold text-uppercase fs-12">
                      Sort by:{" "}
                    </span>
                    <span class="text-muted">
                      Current Year
                      <i class="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">
                      Today
                    </a>
                    <a class="dropdown-item" href="#">
                      Last Week
                    </a>
                    <a class="dropdown-item" href="#">
                      Last Month
                    </a>
                    <a class="dropdown-item" href="#">
                      Current Year
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-0">
              <ul class="list-inline main-chart mb-0">
                <li class="list-inline-item chart-border-left me-0 border-0">
                  <h4 class="text-primary">
                    55
                    <span class="text-muted d-inline-block fs-13 align-middle ms-2">
                      Live
                    </span>
                  </h4>
                </li>
                <li class="list-inline-item chart-border-left me-0">
                  <h4>
                    35
                    <span class="text-muted d-inline-block fs-13 align-middle ms-2">
                      Scheduled
                    </span>
                  </h4>
                </li>
              </ul>

              <div
                id="revenue-expenses-charts"
                data-colors='["--vz-success", "--vz-danger"]'
                class="apex-charts"
                dir="ltr"
              ></div>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">
                Clients Status
              </h4>
              <div class="flex-shrink-0">
                <div class="dropdown card-header-dropdown">
                  <a
                    class="text-reset dropdown-btn"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="text-muted">
                      02 Nov 2021 to 31 Dec 2021
                      <i class="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">
                      Today
                    </a>
                    <a class="dropdown-item" href="#">
                      Last Week
                    </a>
                    <a class="dropdown-item" href="#">
                      Last Month
                    </a>
                    <a class="dropdown-item" href="#">
                      Current Year
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#customDateModal"
                    >
                      Custom
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="table-responsive table-card">
                <table class="table table-borderless table-hover table-nowrap align-middle mb-0">
                  <thead class="table-light">
                    <tr class="text-muted">
                      <th scope="col">Clients</th>
                      <th scope="col" style={{ width: "20%" }}>
                        Videos Created
                      </th>
                      <th scope="col" style={{ width: "16%" }}>
                        Status
                      </th>
                      <th scope="col" style={{ width: "12%" }}>
                        On Boarded Date
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>PolicyChayan</td>
                      <td>10</td>

                      <td>
                        <span class="badge bg-success-subtle text-success p-2">
                          Active
                        </span>
                      </td>
                      <td>
                        <div class="text-nowrap">Jan 2, 2024</div>
                      </td>
                    </tr>
                    <tr>
                      <td>PolicyBazaar</td>
                      <td>Instagram</td>

                      <td>
                        <span class="badge bg-danger-subtle text-danger p-2">
                          InActive
                        </span>
                      </td>
                      <td>
                        <div class="text-nowrap">Jan 23, 2024</div>
                      </td>
                    </tr>
                    <tr>
                      <td>PolicyHai</td>
                      <td>Facebook</td>
                      <td>
                        <span class="badge bg-success-subtle text-success p-2">
                          Active
                        </span>
                      </td>
                      <td>
                        <div class="text-nowrap">Jan 23, 2024</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-12">
          <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Videos Status</h4>
              <div class="flex-shrink-0">
                <select
                  class="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  <option>Scheduled</option>
                  <option value="2">Live</option>
                  <option value="4">Cancelled</option>
                  <option value="3" selected="">
                    All
                  </option>
                </select>
              </div>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                <table
                  id="scroll-horizontal"
                  class="table table-bordered nowrap align-middle "
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "30%" }}>
                        Invoice No
                      </th>
                      <th scope="col" style={{ width: "30%" }}>
                        Client Name
                      </th>
                      <th scope="col" style={{ width: "30%" }}>
                        Influencer Name
                      </th>
                      <th scope="col" style={{ width: "30%" }}>
                        Channel Name
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Video Status
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Scheduled Date
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Final Price
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Advance Payment
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Pending Payment
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Assigned to
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>INV18012023-001</td>
                      <td>PolicyChayan</td>
                      <td>Invests Today</td>
                      <td>
                        <a
                          href="#javascript: void(0);"
                          class="text-body fw-medium"
                        >
                          Youtube
                        </a>
                      </td>
                      <td>
                        {" "}
                        <span class="badge bg-warning-subtle text-warning p-2">
                          Scheduled
                        </span>
                      </td>
                      <td>14-Dec-2023</td>
                      <td>1L</td>
                      <td>50k</td>
                      <td>50k</td>
                      <td>Anna Adame</td>
                    </tr>

                    <tr>
                      <td>INV18012023-002</td>
                      <td>PolicyBazaar</td>
                      <td>Stock Mania</td>
                      <td>
                        <a
                          href="#javascript: void(0);"
                          class="text-body fw-medium"
                        >
                          Youtube
                        </a>
                      </td>
                      <td>
                        {" "}
                        <span class="badge bg-success-subtle text-success p-2">
                          Live
                        </span>
                      </td>
                      <td>14-Dec-2023</td>
                      <td>1L</td>
                      <td>20k</td>
                      <td>80k</td>
                      <td>Anna Adame</td>
                    </tr>
                    <tr>
                      <td>INV18012023-003</td>
                      <td>GeoAdsMedia</td>
                      <td>Stock Mania</td>
                      <td>
                        <a
                          href="#javascript: void(0);"
                          class="text-body fw-medium"
                        >
                          Youtube
                        </a>
                      </td>
                      <td>
                        {" "}
                        <span class="badge bg-danger-subtle text-danger p-2">
                          Cancelled
                        </span>
                      </td>
                      <td>14-Dec-2023</td>
                      <td>1L</td>
                      <td>N.A</td>
                      <td>N.A</td>
                      <td>Anna Adame</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
