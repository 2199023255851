
import React, { useEffect, useState } from 'react';

const FormattedInput = ({ label, name, value, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestion, setSuggestion] = useState('');


  const formatAmount = (amount) => {
    const formatNumber = (value, unit) => {
      const formatted = (Math.floor(value * 100) / 100).toFixed(2); // Format to two decimals
      return (parseFloat(formatted) % 1 === 0 ? parseFloat(formatted).toFixed(0) : formatted) + unit; // Remove trailing .00
    };
    if (amount >= 10000000) {
      return formatNumber(amount / 10000000, 'Cr');
    } else if (amount >= 100000) {
      return formatNumber(amount / 100000, 'L');
    } else if (amount >= 1000) {
      return formatNumber(amount / 1000, 'K');
    } else {
      return parseFloat(amount) % 1 === 0 ? parseInt(amount) : amount; // Remove trailing .00 if a whole number
    }
  };
  

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setInputValue(value);
    setSuggestion(formatAmount(value));
    onChange({
      target: {
        name,
        value: value,
      },
    });
  };

  useEffect(() => {
    setInputValue(value);
    setSuggestion(formatAmount(value));
  }, [value]);

  return (
    <div>
      <input
        type="text"
        name={name}
        value={inputValue}
        onChange={handleChange}
        className="form-control"
        placeholder="Enter amount"
      />
      {inputValue && <div className='formating' style={{ background: '#099885', color: '#fff', padding: '3px' }}> {suggestion}</div>}
    </div>
  );
};
export default FormattedInput;

