// src/features/profile/profileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

export const fetchUserProfile = createAsyncThunk('profile/fetchUserProfile', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get('/api/profile');
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateUserProfile = createAsyncThunk('profile/updateUserProfile', async (profileData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put('/api/profile', profileData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default profileSlice.reducer;
