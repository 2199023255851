import React from 'react';
import { Link } from 'react-router-dom';

const ClientsNavbar = () => {
    return (
        <>
            <li class="nav-item">
                <a class="nav-link menu-link active" href="#sidebarDashboards" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarDashboards">
                    <i class="ri-rocket-line"></i>
                    <span data-key="t-influencers">My Request</span>
                </a>
                <div class="collapse menu-dropdown" id="sidebarDashboards">
                    <ul class="nav nav-sm flex-column">
                        <li class="nav-item">
                            <Link to="/client-all-request" className="nav-link" data-key="t-analytics">All Request</Link>

                        </li>
                        {/* <li class="nav-item">
                        <Link to="/rejected-influencers" className="nav-link" data-key="t-analytics">Rejected Influencers</Link>
                            
                        </li>
                        <li class="nav-item">
                        <Link to="/suggested-influencers" className="nav-link" data-key="t-analytics">Suggested Influencers</Link>
                            
                        </li> */}
                    </ul>
                </div>
            </li>
            {/* <li class="nav-item">
                <a class="nav-link menu-link" href="#sidebarVideos" data-bs-toggle="collapse" role="button"
                    aria-expanded="false" aria-controls="sidebarVideos">
                    <i class="ri-rocket-line"></i>
                    <span data-key="t-videos">Videos</span>
                </a>
                <div class="collapse menu-dropdown" id="sidebarVideos">
                    <ul class="nav nav-sm flex-column">
                        <li class="nav-item">
                        <Link to="/upcoming-videos" className="nav-link" data-key="t-analytics">Upcoming Videos</Link>
                           
                        </li>
                        <li class="nav-item">
                        <Link to="/past-videos" className="nav-link" data-key="t-analytics"> Past Videos</Link>
                            
                        </li>
                        <li class="nav-item">
                        <Link to="/all-videos" className="nav-link" data-key="t-analytics">All Videos</Link>
                            
                        </li>
                    </ul>
                </div>
            </li> */}
        </>
    );
}

export default ClientsNavbar;
