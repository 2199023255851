import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";

const DetailClients = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedData = location.state;

  useEffect(() => {
    console.log("clientData ==>", selectedData);
  }, [selectedData]);

  const handleBackButton = () => {
    navigate(`/all-influencer`);
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-6">
              <h4>Infuencer's Details</h4>
            </div>
            <div className="col-sm-6 d-flex justify-content-end my-2">
              <small>
                <button className="btn btn-primary px-4 py-1" onClick={handleBackButton}><small>Back</small></button>
              </small>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="p-0">
                <table className="table table-striped table-bordered">
                  <tbody>
                    <tr>
                      <td className="w-25 p-3">  Name </td>
                      <td>{selectedData?.name}</td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Concern Person Name:</td>
                      <td>{selectedData?.concern_person_name}</td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Phone No:</td>
                      <td>{selectedData?.mobile_no}</td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Email:</td>
                      <td>{selectedData?.email}</td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Categories:</td>
                      <td>
                        {selectedData?.category?.map((item, index) => (
                          <span key={index}>
                            {item?.label}
                            {index < selectedData.category.length - 1
                              ? ", "
                              : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Languages:</td>
                      <td>
                        {selectedData?.language?.map((lang, index) => (
                          <span key={index}>
                            {lang?.label}
                            {index < selectedData.language.length - 1
                              ? ", "
                              : ""}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Budget:</td>
                      <td>{selectedData?.budget}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailClients;
