import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

// Create an async thunk for fetching data
export const fetchDmSubReq = createAsyncThunk('subrequest/fetchDmSubReq', async ({inf_req_id}, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/dm/view_influencer_req', {
          inf_req_id
        });
        return {data: response.data };
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

const initialClientState = {
    data: [] 
};

const dmSubRequestSlice = createSlice({
    name: 'subrequest',
    initialState: {
        ...fetchDmSubReq
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDmSubReq.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDmSubReq.fulfilled, (state, action) => {
                const { status_id, data } = action.payload;
                state.status = 'succeeded'; 
                    state.subRequestData = data;
                
            })
            .addCase(fetchDmSubReq.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default dmSubRequestSlice.reducer;
