import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Common from '../layout/common';
import useDecodedToken from '../features/utils/hooks/useDecodedToken';

const ProtectedRoute = ({ children, roles }) => {
  const decodedToken = useDecodedToken();
  
  const user = useSelector((state) => state.auth.user) || JSON.parse(localStorage.getItem('user'));
  if (!user || !user.data || !user.data.jwt_token || !user.status) {
    return <Navigate to="/" />;
  }

  if (roles && decodedToken && !roles.includes(decodedToken.UsrRoleName)) {
    return <Navigate to="/unauthorized" />;
  }
  

  return (
    <>
      <div id="layout-wrapper">
        <Common decodedToken={decodedToken}/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>)
};

export default ProtectedRoute;
