import { useEffect, useState } from 'react';
import { decodeToken } from '../decodeToken';

const useDecodedToken = () => {
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = decodeToken(token);
      setDecodedToken(decoded);
    }
  }, []);

  return decodedToken;
};

export default useDecodedToken;
