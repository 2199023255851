import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import Select from "react-select";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { useNavigate } from "react-router-dom";
import { clientInfluencerDataRequest } from "../../features/influencerRequestSlice/influencerRequestSlice";
import { formatDate } from "../../utils/dateUtils";
import moment from "moment";

export const headerData = {
  sr_no: "SR No.",
  name: "Assigned By",
  product: "Product ",
  req_code: "Request Code",
  show_status: "Status",
  sub_req_code: "Sub Request Code",
  action: "Action",
  createdDate: "Created Date"
};

const ClientInfluencerRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const decodeToken = useDecodedToken();
  const { influencerRequestData, status } = useSelector(
    (state) => state.requestInfluencer
  );
  const [clientsData, setClientsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage, setClientsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedData, setSelectedData] = useState("");

  useEffect(() => {
    dispatch(clientInfluencerDataRequest());
  }, [dispatch]);

  useEffect(() => {
    if (status === "succeeded") {
      setClientsData(influencerRequestData?.data || []);
    }
  }, [status, influencerRequestData]);

  // Sorting logic
  const sortTable = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Apply sorting
  const sortedData = [...clientsData].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (sortConfig.direction === "ascending") {
      return aValue.toString().localeCompare(bValue.toString());
    }
    return bValue.toString().localeCompare(aValue.toString());
  });

  

  // Get current clients
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = sortedData.slice(
    indexOfFirstClient,
    indexOfLastClient
  );

// Handle records per page change
  const handleClientsPerPageChange = (selectedOption) => {
    setClientsPerPage(
      selectedOption.value === "All" ? clientsData.length : selectedOption.value
    );
    setCurrentPage(1); // Reset to first page whenever per page value changes
  };

  const perPageOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: "2000", label: "All" } // Add 'All' option
  ];

  const handleShowDetails = (rowData) => {
    setSelectedData(rowData);
  };

  const handleDetailClient = (rowData) => {
    navigate(`/client-influencer-request-details/${rowData?.id}`, { state: rowData })
  };

 useEffect( () => {
  console.log("currentClients currentClientscurrentClientscurrentClients ==>", currentClients);
  
 }, [currentClients])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">All Clients</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li className="breadcrumb-item active">All Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card" id="leadsList">
            <div className="card-header border-0">
              <div className="row g-4 align-items-center">
                <div className="col-sm-1">
                  <label className="small">Select Records</label>
                  <Select
                    className="basic-single small"
                    classNamePrefix="select"
                    name="perPage"
                    options={perPageOptions}
                    defaultValue={perPageOptions[0]}
                    onChange={handleClientsPerPageChange}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <table
                id="example"
                className="table table-bordered nowrap table-striped align-middle"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>{headerData.sr_no}</th>
                    <th>{headerData.action}</th>
                    <th>{headerData.show_status}</th>
                    <th>{headerData.req_code}</th>
                    <th>{headerData.sub_req_code}</th>
                    
                    <th>{headerData.name}</th>
                    <th onClick={() => sortTable("name")}>
                      {headerData.product}
                    </th>
                    
                    
                    <th>{headerData.createdDate}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentClients.length > 0 ? (
                    currentClients.map((dataActive, index) => (
                      <tr key={index}>
                        <td>
                          {(currentPage - 1) * clientsPerPage + index + 1}
                        </td>
                        <td>
                          <ul className="list-inline hstack mb-0">
                            <li
                              className="list-inline-item"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              data-bs-placement="top"
                              aria-label="View"
                              data-bs-original-title="View"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    {decodeToken?.UsrRoleId === 4
                                      ? decodeToken?.UsrId ===
                                          dataActive?.assigned_to_id ||
                                        decodeToken?.UsrId ===
                                          dataActive?.tmp_assigned_to_id
                                        ? "View Details"
                                        : "Restriction  to show"
                                      : "View Details"}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className={`btn btn-sm btn-info edit-item-btn ${
                                    decodeToken?.UsrRoleId === 4
                                      ? decodeToken?.UsrId ===
                                          dataActive?.assigned_to_id ||
                                        decodeToken?.UsrId ===
                                          dataActive?.tmp_assigned_to_id
                                        ? ""
                                        : "not-allowed-cursor"
                                      : ""
                                  }`}
                                  onClick={ () => handleDetailClient(dataActive)}
                                  style={{
                                    cursor:
                                      decodeToken?.UsrRoleId === 4
                                        ? decodeToken?.UsrId ===
                                            dataActive?.assigned_to_id ||
                                          decodeToken?.UsrId ===
                                            dataActive?.tmp_assigned_to_id
                                          ? "pointer"
                                          : "not-allowed"
                                        : "pointer"
                                  }}
                                >
                                  <i className="ri-send-plane-fill align-bottom"></i>
                                </Button>
                              </OverlayTrigger>
                            </li>
                          </ul>
                        </td>
                        
                        <td>
                          <span
                              className={`badge   ${
                              dataActive.show_status === "Testing Status"
                                  ? "bg-success-subtle text-success"
                                  : dataActive.show_status === "Pending" &&
                                  "bg-warning-subtle text-warning"
                              }`}
                          >
                              
                              <OverlayTrigger
                              placement="top"
                              overlay={
                                  <Tooltip id="tooltip-top">
                                  {dataActive.show_status}
                                  </Tooltip>
                              }
                              >
                              <button
                                  className="btn btn-default btn-sm m-0 p-0"
                              >
                                  {dataActive.show_status}{" "}
                              </button>
                              </OverlayTrigger>
                          
                          </span>
                        </td>




                        <td>
                          <span className={`badge  bg-info-subtle text-info `}  > 
                              <OverlayTrigger
                                  placement="top"
                                  overlay={
                                  <Tooltip id="tooltip-top">
                                      Request : {dataActive.req_code}
                                  </Tooltip>
                                  }
                              >
                                  <button
                                  onClick={() => handleShowDetails(dataActive)}
                                  className="btn btn-default btn-sm m-0 p-0"
                                  >
                                  {dataActive.req_code}{" "}
                                  </button>
                              </OverlayTrigger>
                          </span>
                        </td>
                        <td>
                          <span className={`badge  bg-warning-subtle text-warning `}  > 
                              <OverlayTrigger
                                  placement="top"
                                  overlay={
                                  <Tooltip id="tooltip-top">
                                      Sub Request : {dataActive.sub_req_code}
                                  </Tooltip>
                                  }
                              >
                                  <button
                                  className="btn btn-default btn-sm m-0 p-0"
                                  >
                                  {dataActive.sub_req_code}{" "}
                                  </button>
                              </OverlayTrigger>
                          </span>
                        </td>
                        
                        <td>{dataActive.name}</td>
                        <td>{dataActive.product}</td>
                        <td>{formatDate(dataActive.created_at)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row">
                {clientsPerPage !== "2000" && (
                  <>
                    <div className="col-sm-6">
                      Showing{" "}
                      {Math.min(
                        (currentPage - 1) * clientsPerPage + 1,
                        influencerRequestData.pagination.totalItems
                      )}{" "}
                      to{" "}
                      {Math.min(
                        currentPage * clientsPerPage,
                        influencerRequestData.pagination.totalItems
                      )}{" "}
                      of {influencerRequestData.pagination.totalItems} entries
                    </div>
                    <div className="col-sm-6">
                      <Pagination className="justify-content-end">
                        <Pagination.Prev
                          onClick={() =>
                            setCurrentPage(
                              currentPage > 1 ? currentPage - 1 : currentPage
                            )
                          }
                          disabled={currentPage === 1}
                        />
                        {Array.from(
                          { length: influencerRequestData.pagination.totalPages },
                          (_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          )
                        )}
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage(
                              currentPage < influencerRequestData.pagination.totalPages
                                ? currentPage + 1
                                : currentPage
                            )
                          }
                          disabled={
                            currentPage === influencerRequestData.pagination.totalPages
                          }
                        />
                      </Pagination>
                    </div>
                  </>
                )}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientInfluencerRequest;
