import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';
import commonAxiosInstance from '../../api/commonAxiosInstance';

const initialState = {
  user: null,
  status: 'idle',
  error: null,
};

export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/user/login', credentials);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const registerUser = createAsyncThunk('auth/registerUser', async (userDetails, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/influencer/sign_up', userDetails);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


export const signUp = createAsyncThunk('auth/signUp', async (formData, { rejectWithValue }) => {
  try {
    const response = await commonAxiosInstance.post('/client/sign_up', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

 
export const changePassword = createAsyncThunk('auth/changePassword', async (formData, { rejectWithValue }) => {
  try {
    const response = await commonAxiosInstance.post('/user/change_password', formData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        if (action.payload.data && action.payload.data.jwt_token) {
          localStorage.setItem('token', JSON.stringify(action.payload.data.jwt_token)); // Save token
          localStorage.setItem('user', JSON.stringify(action.payload)); // Save user data
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.status = 'succeeded';
          state.user = action.payload;
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(signUp.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientData = action.payload;
        state.error = null;
        state.errorData = null;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message;
        state.errorData = action.payload.form_err;
      })
       
      
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientData = action.payload;
        state.error = null;
        state.errorData = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message;
        state.errorData = action.payload.form_err;
      })
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
