import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const DetailClients = (props) => {
    const { showModal, setShowModal, selectedData } = props;
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title> Client's Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0">
                                        <table className="table table-striped table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="w-25 p-3">Company Name </td>
                                                    <td>{selectedData?.company_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Concern Person Name:</td>
                                                    <td>{selectedData?.concern_person_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Phone No:</td>
                                                    <td>{selectedData?.mobile_no}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Email:</td>
                                                    <td>{selectedData?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Categories:</td>
                                                    <td>
                                                        {selectedData?.category?.map((item, index) => (
                                                            <span key={index}>{item?.label}{index < selectedData.category.length - 1 ? ', ' : ''}</span>
                                                        ))}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Languages:</td>
                                                    <td>
                                                        {selectedData?.language?.map((lang, index) => (
                                                            <span key={index}>{lang?.label}{index < selectedData.language.length - 1 ? ', ' : ''}</span>
                                                        ))}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-25 p-3">Budget:</td>
                                                    <td>{selectedData?.budget}</td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DetailClients;
