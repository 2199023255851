// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from './features/profile/Profile';
import LayoutDefault from './layout/LayoutDefault';
import ClientSignUp from './components/clientSignup/ClientSignUp';
import SignUp from './components/signup/SignUp';
import AssignedClients from './components/clientSignup/AssignedClients';
import ActiveClients from './components/clientSignup/ActiveClients';
import InactiveClients from './components/clientSignup/InactiveClients';
import AllClients from './components/clientSignup/AllClients';
import PendingClients from './components/clientSignup/PendingClients';
import { fetchClientData } from "./features/clientSlice/clientSlice";
import Unauthorized from './components/Unauthorized';
import RejectedInfluencers from './components/clientInfluencer/RejectedInfluencers';
import SuggestedInfluencers from './components/clientInfluencer/SuggestedInfluencers';
import ApprovedInfluencers from './components/clientInfluencer/ApprovedInfluencers';
import ActiveInfluencer from './components/dmExecutive/ActiveInfluencer'; 
import InactiveInfluencer from './components/dmExecutive/InactiveInfluencer';
import AllInfluencer from './components/dmExecutive/AllInfluencer';
import DetailInfluencer from './components/dmExecutive/DetailInfluencer';
import PendingInfluencer from './components/dmExecutive/PendingInfluencer';  
import AssignedInfluencer from './components/dmExecutive/AssignedInfluencer'; 

import ClientRequest from './components/clientRequest/ClientRequest';  
import DetailClients from './components/clientSignup/DetailClients';
import InfuencerRequest from './components/dmRequest/InfuencerRequest';
import ChangePassword from './components/profile/ChangePassword';
import ClientInfluencerRequest from './components/clientInfluencerRequest/ClientInfluencerRequest';
import ClientInfluencerRequestDetails from './components/clientInfluencerRequest/ClientInfluencerRequestDetails';



function App() {

  return (
    <><ToastContainer />
      <Routes>
        {/* Login */}
        <Route path="/" element={<LayoutDefault />} />
        {/* New User Register */}
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/client-sign-up" element={<ClientSignUp />} />
        {/* Dashboard */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        {/* Setting */}
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/change-password" element={<ProtectedRoute><ChangePassword  /></ProtectedRoute>} />
        {/* Error Page */}
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* Other Page */}
        <Route path="/client-all-request" element={<ProtectedRoute><ApprovedInfluencers /></ProtectedRoute>} />
        <Route path="/rejected-influencers" element={<ProtectedRoute><RejectedInfluencers /></ProtectedRoute>} />
        <Route path="/suggested-influencers" element={<ProtectedRoute><SuggestedInfluencers /></ProtectedRoute>} />
        <Route path="/assigned-clients" element={<ProtectedRoute><AssignedClients  /></ProtectedRoute>} />
        <Route path="/active-clients" element={<ProtectedRoute><ActiveClients  /></ProtectedRoute>} />
        <Route path="/pending-clients" element={<ProtectedRoute><PendingClients /></ProtectedRoute>} />
        <Route path="/inactive-clients" element={<ProtectedRoute><InactiveClients /></ProtectedRoute>} />
        <Route path="/all-clients" element={<ProtectedRoute><AllClients /></ProtectedRoute>} />
        <Route path="/active-influencer" element={<ProtectedRoute><ActiveInfluencer  /></ProtectedRoute>} />
        <Route path="/active-influencer" element={<ProtectedRoute><ActiveInfluencer  /></ProtectedRoute>} />
        <Route path="/inactive-influencer" element={<ProtectedRoute><InactiveInfluencer  /></ProtectedRoute>} />
        <Route path="/pending-influencer" element={<ProtectedRoute><PendingInfluencer  /></ProtectedRoute>} />
        <Route path="/all-influencer" element={<ProtectedRoute><AllInfluencer  /></ProtectedRoute>} />
        <Route path="/assigned-influencer" element={<ProtectedRoute><AssignedInfluencer  /></ProtectedRoute>} />
        <Route path="/client-request-in-sales" element={<ProtectedRoute><ClientRequest  /></ProtectedRoute>} />
        <Route path="/client-details/:id" element={<ProtectedRoute><DetailClients  /></ProtectedRoute>} />
        <Route path="/influencer-details/:id" element={<ProtectedRoute><DetailInfluencer  /></ProtectedRoute>} />
        <Route path="/client-request-in-dm" element={<ProtectedRoute><InfuencerRequest  /></ProtectedRoute>} />
        <Route path="/client-sub-request-in-dm" element={<ProtectedRoute><ClientInfluencerRequest  /></ProtectedRoute>} />
        <Route path="/client-influencer-request-details/:id" element={<ProtectedRoute><ClientInfluencerRequestDetails  /></ProtectedRoute>} />
        
        </Routes>
    </>
  );
}

export default App;
