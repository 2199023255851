import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientData } from "../../features/clientRequstSlice/infuencerRequestSlice";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { Button, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import AssigntoDm from "./AssigntoDm"; 
import VewRequest from "./VewRequest"; 
import VewSubRequest from "./ViewSubRequest"; 
import { formatDate } from "../../utils/dateUtils";

import { format } from 'date-fns';
import Select from 'react-select';
import { Link } from "react-router-dom";
import moment from "moment";

export const headerData = {
    sr_no: "SR No.",
    action: "Action",
    sub_req_count: "Sub Request Count",
    sub_req: "Sub Request",
    inHouseStatus: "Inhouse Status",
    status: "status",
    request_id: "Request ID",
    pro_name: "Product Name",
    no_influencer: "Required Influencer",
    budget: "Client Budget",
    category: "Categories",
    requestedDate:"Requested Date",
};

const InfuencerRequest = () => {
    const decodedToken = useDecodedToken();
    const dispatch = useDispatch();
    const { allClientData, status } = useSelector((state) => state.clients);
    const [clientsData, setClientsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    useEffect(() => {
        dispatch(fetchClientData({ status_id: '99', page: currentPage, limit: clientsPerPage === 2000 ? "All" : clientsPerPage }));
    }, [dispatch, currentPage, clientsPerPage]);

    useEffect(() => {
        if (status === 'succeeded') {
            setClientsData(allClientData?.data || []);
        }
    }, [status, allClientData]);

   

    // Sorting logic
    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Apply sorting
    const sortedData = [...clientsData].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue === undefined || bValue === undefined) {
            return 0;
        }

        if (sortConfig.direction === 'ascending') {
            return aValue.toString().localeCompare(bValue.toString());
        }
        return bValue.toString().localeCompare(aValue.toString());
    });

  
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [subRequestModal, setSubRequestModal] = useState(false);

    const [showAssignSaleModal, setAssignSaleModel] = useState(false); 
    const [selectedData, setSelectedData] = useState(null);

     
    
    const handleAssigntosaleClient = (rowData) => {
        setAssignSaleModel(true);
        setSelectedData(rowData);
    };

    const handleDetailClient = (rowData) => {
        setShowDetailModal(true);
        setSelectedData(rowData);
    };

    const handleSubRequestClient = (rowData) => {
        setSubRequestModal(true);
        setSelectedData(rowData);
    };
    
    const handleClientsPerPageChange = (selectedOption) => {
        setClientsPerPage(selectedOption.value);
        setCurrentPage(1); // Reset to first page whenever per page value changes
    };
    const perPageOptions = [
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 'All', label: 'All' }, // Add 'All' option
    ];

    return (
        <>
        <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0">All Client Request </h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li class="breadcrumb-item active">All Client Request</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="leadsList">
                        <div className="card-header border-0">
                            <div className="row g-4 align-items-center">
                                <div className="col-sm-1">
                                    <label className="small">Select Records</label>
                                    <Select
                                        className="basic-single small"
                                        classNamePrefix="select"
                                        name="perPage"
                                        options={perPageOptions}
                                        defaultValue={perPageOptions[0]}
                                        onChange={handleClientsPerPageChange}
                                    />
                                </div>
                                <div className="col-sm-auto ms-auto">
                                    <div className="hstack gap-2">
                                        <button type="button" className="btn btn-info" data-bs-toggle="offcanvas" href="#offcanvasExample">
                                            <i className="ri-filter-3-line align-bottom me-1"></i> Filters
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <table id="example" className="table table-bordered nowrap table-striped align-middle" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>{headerData.sr_no}</th>
                                        <th>{headerData.action}</th>
                                        {/* <th>{headerData.inHouseStatus}</th> */}
                                        <th>{headerData.status}</th>
                                        <th>{headerData.request_id}</th>
                                        <th>{headerData.sub_req_count}</th>
                                        <th>{headerData.sub_req}</th>
                                        <th>{headerData.no_influencer}</th>
                                        <th>{headerData.pro_name}</th>
                                        <th>{headerData.budget}</th>
                                        <th>{headerData.requestedDate}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedData.length > 0 ? (
                                        sortedData.map((dataActive, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * clientsPerPage + index + 1}</td>
                                                <td>
                                                    <ul className="list-inline hstack mb-0">
                                                    <li
                                                           className="list-inline-item"
                                                           data-bs-toggle="tooltip"
                                                           data-bs-trigger="hover"
                                                           data-bs-placement="top"
                                                           aria-label="View"
                                                           data-bs-original-title="View"
                                                          >
                                                           <OverlayTrigger
                                                              placement="top"
                                                              overlay={
                                                              <Tooltip id="tooltip-top">
                                                                 Request Assign to DM Team
                                                              </Tooltip>
                                                              }
                                                              >
                                                          <Button   onClick={() => handleAssigntosaleClient(dataActive)}   className="btn btn-sm btn-warning edit-item-btn btn btn-primary disabled-btn"  >
                                                              <i className="ri-share-fill align-bottom me-1"  ></i>
                                                          </Button>
                                                          </OverlayTrigger> 
                                                         </li>
                                                     

                                                         
                                                    </ul>
                                                </td>
                                                {/* <td>
                                                    <span
                                                        className={`badge   ${
                                                        dataActive.client_status === "Sent to DM"
                                                            ? "bg-success-subtle text-success"
                                                            : dataActive.client_status === "Assigned"
                                                            ? "bg-info-subtle text-info"
                                                            : dataActive.client_status === "show_status"
                                                            ? "bg-danger-subtle text-danger"
                                                            : dataActive.client_status === "Pending" &&
                                                            "bg-warning-subtle text-warning"
                                                        }`}
                                                    >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {dataActive.client_status}
                                                            </Tooltip>
                                                            }
                                                        >
                                                            <button 
                                                            className="btn btn-default btn-sm m-0 p-0"
                                                            >
                                                            {dataActive.client_status}{" "}
                                                            </button>
                                                        </OverlayTrigger>
                                                    </span>
                                                </td> */}
                                                <td>
                                                    <span
                                                        className={`badge   ${
                                                        dataActive.dm_status === "Sent to DM"
                                                            ? "bg-success-subtle text-success"
                                                            : dataActive.dm_status === "Assigned"
                                                            ? "bg-info-subtle text-info"
                                                            : dataActive.dm_status === "show_status"
                                                            ? "bg-danger-subtle text-danger"
                                                            : dataActive.dm_status === "Pending" &&
                                                            "bg-warning-subtle text-warning"
                                                        }`}
                                                    >
                                                        
                                                        <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip-top">
                                                            {dataActive.dm_status}
                                                            </Tooltip>
                                                        }
                                                        >
                                                        <button
                                                            className="btn btn-default btn-sm m-0 p-0"
                                                        >
                                                            {dataActive.dm_status}{" "}
                                                        </button>
                                                        </OverlayTrigger>
                                                    
                                                    </span>
                                                </td>
                                                  
                                                <td>
                                                    
                                                    <span className={`badge  bg-info-subtle text-info `}  > 
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                            <Tooltip id="tooltip-top">
                                                               {'View'}
                                                            </Tooltip>
                                                            }
                                                        >
                                                            <button
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handleDetailClient(dataActive)}
                                                            className="btn btn-default btn-sm m-0 p-0"
                                                            >
                                                            {dataActive.req_code}{" "}
                                                            </button>
                                                        </OverlayTrigger>
                                                    </span>
                                                </td>
  
                                                <td>{dataActive.SubReqCount}</td>
                                                <td> <span   className={`badge  bg-info-subtle text-info `}  > 
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                            <Tooltip id="tooltip-top">
                                                              {dataActive.SubReqCount === 0
                                                                    ? "View Restricted" 
                                                                    : "View Sub Request"}
                                                            </Tooltip>
                                                            }
                                                        >
                                                            {dataActive.SubReqCount === 0
                                                                    ? (<button
                                                                        style={{ cursor: "pointer" }} 
                                                                        className="btn btn-default btn-sm m-0 p-0"
                                                                        >
                                                                         View
                                                                        </button>) 
                                                                    : (<button
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => handleSubRequestClient(dataActive)}
                                                                        className="btn btn-default btn-sm m-0 p-0"
                                                                        >
                                                                         View
                                                                        </button>)}
                                                            
                                                        </OverlayTrigger>
                                                    </span></td>




                                                <td>{dataActive.no_of_influencer}</td>
                                                <td>{dataActive.product}</td>
                                                <td>{dataActive.budget}</td> 
                                                <td>  {formatDate(dataActive.to_date)} </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr><td colSpan="12" className="text-center">No data available</td></tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="row">
                                {clientsPerPage !== '2000' && (<>
                                    <div className="col-sm-6">
                                        Showing{" "}
                                        {Math.min(
                                            (currentPage - 1) * clientsPerPage + 1,
                                            allClientData.pagination.totalItems
                                        )}{" "}
                                        to{" "}
                                        {Math.min(
                                            currentPage * clientsPerPage,
                                            allClientData.pagination.totalItems
                                        )}{" "}
                                        of {allClientData.pagination.totalItems} entries
                                    </div>
                                    <div className="col-sm-6">
                                  
                                        <Pagination className="justify-content-end">
                                            <Pagination.Prev
                                                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
                                                disabled={currentPage === 1}
                                            />
                                            {Array.from({ length: allClientData.pagination.totalPages }, (_, index) => (
                                                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => setCurrentPage(index + 1)}>
                                                    {index + 1}
                                                </Pagination.Item>
                                            ))}
                                            <Pagination.Next
                                                onClick={() => setCurrentPage(currentPage < allClientData.pagination.totalPages ? currentPage + 1 : currentPage)}
                                                disabled={currentPage === allClientData.pagination.totalPages}
                                            />
                                        </Pagination>
                                        
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <VewRequest showModal={showDetailModal} setShowModal={setShowDetailModal} selectedData={selectedData} />
              <AssigntoDm showModal={showAssignSaleModal} setShowModal={setAssignSaleModel} selectedData={selectedData} />
              <VewSubRequest showModal={subRequestModal} setShowModal={setSubRequestModal} selectedData={selectedData} />
                </>
    );
};

export default InfuencerRequest;
