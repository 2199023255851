import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

// Create an async thunk for fetching influencer request data
export const clientInfluencerDataRequest = createAsyncThunk(
  'influencerRequest/clientInfluencerDataRequest',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dmexe/influencer_sub_req_list', formData);
      console.log("response ==>", response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Create an async thunk for fetching influencer request details
export const clientInfluencerDataRequestDetails = createAsyncThunk(
  'influencerRequestDetails/clientInfluencerDataRequestDetails',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/dmexe/suggest_influencer', formData);
      console.log("response ==>", response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialClientState = {
  data: [],
  pagination: {
    limit: 10,
    page: 1,
    totalItems: 0,
    totalPages: 0
  }
};

// Slice for influencer request data
const influencerRequestSlice = createSlice({
  name: 'influencerRequest',
  initialState: {
    influencerRequestData: { ...initialClientState },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientInfluencerDataRequest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDataRequest.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestData = action.payload;
      })
      .addCase(clientInfluencerDataRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// Slice for influencer request details
const influencerRequestDetailsSlice = createSlice({
  name: 'influencerRequestDetails',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clientInfluencerDataRequestDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clientInfluencerDataRequestDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.influencerRequestDatadetails = action.payload;
      })
      .addCase(clientInfluencerDataRequestDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const influencerRequestReducer = influencerRequestSlice.reducer;
export const influencerRequestDetailsReducer = influencerRequestDetailsSlice.reducer;
