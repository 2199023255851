import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../features/commonSlice/commonSlice";
import MainContent from "./MainContent";

import useDecodedToken from '../../features/utils/hooks/useDecodedToken';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const  decodedToken = useDecodedToken()
  const data = useSelector((state) => state.influencer.data);
  const status = useSelector((state) => state.influencer.status);
  const error1 = useSelector((state) => state.influencer.error);

  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchData());
    }
  }, [status, dispatch, decodedToken]);

  useEffect(() => {
    if (status === 'succeeded') {
      setSalesData(data.data);
    }
  }, [status, data]);
  
  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>{error1}</div>;
  }

  return (
    <MainContent salesData={salesData} decodedToken={decodedToken} />
  );
};

export default Dashboard;
