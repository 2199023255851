import axios from 'axios';
// import { store } from '../app/store';
import { decodeToken } from '../features/utils/decodeToken';

const commonAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});
commonAxiosInstance.interceptors.request.use((config) => {
  const token = process.env.REACT_APP_COMMON_TOKEN;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default commonAxiosInstance;
