import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

// Create an async thunk for fetching data
export const fetchClientData = createAsyncThunk('clients/fetchClientData', async ({ status_id, page, limit }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/sales/client_request_list', {
            status_id,
            page,
            limit,
        });
        return { status_id, data: response.data };
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

const initialClientState = {
    data: [],
    pagination: {
        limit: 0,
        page: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const clientRequestSlice = createSlice({
    name: 'clients',
    initialState: {
        allClientData: { ...initialClientState },
        activeClientData: { ...initialClientState },
        pendingClientData: { ...initialClientState },
        inactiveClientData: { ...initialClientState },
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchClientData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchClientData.fulfilled, (state, action) => {
                const { status_id, data } = action.payload;
                state.status = 'succeeded';
                if (status_id === '1') {
                    state.activeClientData = data;
                } else if (status_id === '0') {
                    state.pendingClientData = data;
                } else if (status_id === '4') {
                    state.inactiveClientData = data;
                } else if (status_id === '99') {
                    state.allClientData = data;
                }
            })
            .addCase(fetchClientData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default clientRequestSlice.reducer;
