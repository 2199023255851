import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientData } from "../../features/influencerSlice/influencerSlice";
import { Button, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddNewClients from "./AddNewClients";
import UpdateInfluencer from "./UpdateInfluencer";
import DetailInfluencer from "./DetailInfluencer";
import AssigntoDm from "./AssigntoDm";
import UpdateStatus from "./UpdateStatus";
import { format } from 'date-fns';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { formatDate } from "../../utils/dateUtils";
import CryptoJS from "crypto-js";
import moment from "moment";

export const headerData = {
    sr_no: "SR No.",
    action: "Action",
    client_name: "Influencer Name",
    onboard_date: "Onboard Date",
    status: "Status",
    assign_by: "Assigned By",
    tmp_assign_to: "Temporary Assigned To",
    tmp_assign_date: "Temporary Assigned To Date", 
    category: "Categories",
    email: "Email id",
    contact_per: "Contact Person",
    mobile: "Mobile Number",
    managed_by_dm: "Managed By DM",
    tmp_managed_by_dm: "Temporary Managed By DM",
    assigned_duration: "Temporary Assigned Duration",
};

const PendingInfluencer = () => {
    const dispatch = useDispatch();
    const decodeToken = useDecodedToken();
    const navigate = useNavigate();
    const { pendingClientData, status } = useSelector((state) => state.clients);
    const [clientsData, setClientsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    useEffect(() => {
        dispatch(fetchClientData({ status_id: '0', page: currentPage, limit: clientsPerPage === 2000 ? "All" : clientsPerPage }));
    }, [dispatch, currentPage, clientsPerPage]);

    useEffect(() => {
        if (status === 'succeeded') {
            setClientsData(pendingClientData?.data || []);
        }
    }, [status, pendingClientData]);

   

    // Sorting logic
    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Apply sorting
    const sortedData = [...clientsData].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue === undefined || bValue === undefined) {
            return 0;
        }

        if (sortConfig.direction === 'ascending') {
            return aValue.toString().localeCompare(bValue.toString());
        }
        return bValue.toString().localeCompare(aValue.toString());
    });

    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showAssignSaleModal, setAssignSaleModel] = useState(false);
    const [showStatusModal, setStatusModel] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const clickAddNew = () => {
        setShowModal(true);
    };
    const handleUpdateClient = (rowData) => {
        setShowUpdateModal(true);
        setSelectedData(rowData);
    };
     

    const handleDetailClient = (rowData) => {
        setSelectedData(rowData);
        const encryptedId = CryptoJS.AES.encrypt(
            rowData.id.toString(),
            "your_secret_key"
          ).toString();
        navigate(`/influencer-details/${rowData?.id}`,  { state: rowData });
      };

    const handleAssigntosaleClient = (rowData) => {
        setAssignSaleModel(true);
        setSelectedData(rowData);
    };
    const handleChangeStatus = (rowData) => {
        setStatusModel(true);
        setSelectedData(rowData);
    };
    const handleClientsPerPageChange = (selectedOption) => {
        setClientsPerPage(selectedOption.value);
        setCurrentPage(1); // Reset to first page whenever per page value changes
    };
    const perPageOptions = [
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 2000, label: "All" } // Add 'All' option
    ];

    return (
        <>
        <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0">Pending Influencer</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li class="breadcrumb-item active">Pending Influencer</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
            <div className="row">
                <div className="col-lg-12">
                   
                    <div className="card" id="leadsList">
                        <div className="card-header border-0">
                            <div className="row g-4 align-items-center">
                                <div className="col-sm-1">
                                    <label className="small">Select Records</label>
                                    <Select
                                        className="basic-single small"
                                        classNamePrefix="select"
                                        name="perPage"
                                        options={perPageOptions}
                                        defaultValue={perPageOptions[0]}
                                        onChange={handleClientsPerPageChange}
                                    />
                                </div>
                                <div className="col-sm-auto ms-auto">
                                    <div className="hstack gap-2">
                                        <button type="button" className="btn btn-info" data-bs-toggle="offcanvas" href="#offcanvasExample">
                                            <i className="ri-filter-3-line align-bottom me-1"></i> Filters
                                        </button>
                                        <Button className="btn btn-success add-btn" onClick={clickAddNew}>
                                            <i className="ri-add-line align-bottom me-1"></i> Add Influencer
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <table id="example" className="table table-bordered nowrap table-striped align-middle" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>{headerData.sr_no}</th>
                                        <th>{headerData.action}</th>
                                        <th onClick={() => sortTable('name')}>{headerData.client_name}</th>
                                        <th>{headerData.onboard_date}</th>
                                        <th>{headerData.status}</th>
                                        <th>{headerData.email}</th>
                                        <th>{headerData.contact_per}</th>
                                        <th>{headerData.mobile}</th>
                                        <th>{headerData.assign_by}</th>
                                        <th>{headerData.managed_by_dm}</th>
                                        <th>{headerData.tmp_managed_by_dm}</th>
                                        <th>{headerData.assigned_duration}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedData.length > 0 ? (
                                        sortedData.map((dataActive, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * clientsPerPage + index + 1}</td>
                                                <td>
                                                    <ul className="list-inline hstack mb-0">
                                                        <li
                                                        className="list-inline-item"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-trigger="hover"
                                                        data-bs-placement="top"
                                                        aria-label="View"
                                                        data-bs-original-title="View"
                                                        >
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                    {dataActive.assigned_to_id === decodeToken?.UsrId ||  dataActive.tmp_assigned_to_id === decodeToken?.UsrId || decodeToken?.UsrRoleId === 3
                                                                        ? "View Detail" 
                                                                        : "View Restricted"}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                
                                                            <Button className="btn btn-sm btn-info edit-item-btn"  onClick={
                                                                dataActive.assigned_to_id === decodeToken?.UsrId ||  dataActive.tmp_assigned_to_id === decodeToken?.UsrId ||decodeToken?.UsrRoleId === 3
                                                                    ? () =>
                                                                        handleDetailClient(dataActive)
                                                                    
                                                                    : (e) => e.preventDefault()
                                                                } >
                                                                <i className="ri-eye-fill align-bottom"></i>
                                                            </Button>
                                                            </OverlayTrigger>
                                                        </li>
  
                                                        
                                                        {decodeToken?.UsrRoleId !== 4 && (
                                                        <li
                                                            className="list-inline-item"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-trigger="hover"
                                                            data-bs-placement="top"
                                                            aria-label="View"
                                                            data-bs-original-title="View"
                                                        >
                                                            <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                {dataActive.status === 1
                                                                    ? "Assign to DM team"
                                                                    : dataActive.status === 5
                                                                    ? "Temprary assign to DM team"
                                                                    : "Assign Restricted"}
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <Button
                                                                className={`btn btn-sm btn-warning edit-item-btn ${
                                                                dataActive.status === 1 ||
                                                                dataActive.status === 5
                                                                    ? ""
                                                                    : "disabled-btn"
                                                                }`}
                                                                onClick={
                                                                dataActive.status === 1
                                                                    ? () =>
                                                                        handleAssigntosaleClient(dataActive)
                                                                    : dataActive.status === 5
                                                                    ? () =>
                                                                        handleAssigntosaleClient(dataActive)
                                                                    : (e) => e.preventDefault()
                                                                }
                                                                style={{
                                                                cursor:
                                                                    dataActive.status === 1 ||
                                                                    dataActive.status === 5
                                                                    ? "pointer"
                                                                    : "not-allowed"
                                                                }}
                                                            >
                                                                <i
                                                                className={`ri-${
                                                                    dataActive.status !== 5
                                                                    ? "share"
                                                                    : "send-plane"
                                                                }-fill align-bottom me-1 ${
                                                                    dataActive.status === 4 ||
                                                                    dataActive.status === 5
                                                                    ? ""
                                                                    : "restricted-icon"
                                                                }`}
                                                                ></i>
                                                            </Button>
                                                            </OverlayTrigger>
                                                        </li>
                                                        )}
                                                    </ul>
                                                </td>
                                                <td>{dataActive.name}</td>
                                                <td>  {formatDate(dataActive.created_at)} </td>
                                                <td>
                                                    <span
                                                        className={`badge w-100 ${
                                                        dataActive.user_status === "Active"
                                                            ? "bg-success-subtle text-success"
                                                            : dataActive.user_status === "Assigned"
                                                            ? "bg-info-subtle text-info"
                                                            : dataActive.user_status === "Inactive"
                                                            ? "bg-danger-subtle text-danger"
                                                            : dataActive.user_status === "Pending" &&
                                                            "bg-warning-subtle text-warning"
                                                        }`}
                                                    >
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {dataActive.user_status}
                                                            </Tooltip>
                                                            }
                                                        >
                                                            <button
                                                            style={{ cursor: "pointer" }}
                                                            onClick={decodeToken?.UsrRoleId === 3 ? () => handleChangeStatus(dataActive) : null}
                                                            className="btn btn-default btn-sm m-0 p-0"
                                                            >
                                                            {dataActive.user_status}{" "}
                                                            </button>
                                                        </OverlayTrigger>
                                                    </span>
                                                </td>

                                                
                                                

                                                

                                            
                                                <td>{dataActive.email}</td>
                                                <td>{dataActive.concern_person_name}</td>
                                                <td>{dataActive.mobile_no}</td>
                                                <td>{dataActive.assigned_by_name ? dataActive.assigned_by_name : '-'}</td>
                                                <td>
                                                    {
                                                        dataActive.assigned_to_id === decodeToken?.UsrId ? (
                                                        <span className="text-success p-2">You</span>
                                                        ) : (
                                                        dataActive.assigned_to_name ? dataActive.assigned_to_name : '-'
                                                        )
                                                    }
                                                </td> 
                                                
                                                <td>
                                                    {
                                                        dataActive.tmp_assigned_to_id === decodeToken?.UsrId ? (
                                                        <span className="text-success p-2">You</span>
                                                        ) : (
                                                        dataActive.tmp_assigned_to_name ? dataActive.tmp_assigned_to_name : '-'
                                                        )
                                                    }
                                                </td>  
                                                
                                                <td> 
                                                    {`${
                                                        dataActive.to_date != "" &&
                                                        dataActive.to_date != null
                                                        ?  formatDate(dataActive.created_at)
                                                        : "-"
                                                    }`}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr><td colSpan="12" className="text-center">No data available</td></tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="row">
                                {clientsPerPage !== '2000' && (<> 
                                    <div className="col-sm-6">
                                        Showing{" "}
                                        {Math.min(
                                            (currentPage - 1) * clientsPerPage + 1,
                                            pendingClientData.pagination.totalItems
                                        )}{" "}
                                        to{" "}
                                        {Math.min(
                                            currentPage * clientsPerPage,
                                            pendingClientData.pagination.totalItems
                                        )}{" "}
                                        of {pendingClientData.pagination.totalItems} entries
                                    </div>
                                    <div className="col-sm-6">

                                        <Pagination className="justify-content-end">
                                            <Pagination.Prev
                                                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
                                                disabled={currentPage === 1}
                                            />
                                            {Array.from({ length: pendingClientData.pagination.totalPages }, (_, index) => (
                                                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => setCurrentPage(index + 1)}>
                                                    {index + 1}
                                                </Pagination.Item>
                                            ))}
                                            <Pagination.Next
                                                onClick={() => setCurrentPage(currentPage < pendingClientData.pagination.totalPages ? currentPage + 1 : currentPage)}
                                                disabled={currentPage === pendingClientData.pagination.totalPages}
                                            />
                                        </Pagination>

                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddNewClients showModal={showModal} setShowModal={setShowModal} />
            <UpdateInfluencer showModal={showUpdateModal} setShowModal={setShowUpdateModal} selectedData={selectedData} />
             <AssigntoDm showModal={showAssignSaleModal} setShowModal={setAssignSaleModel} selectedData={selectedData} />
            <UpdateStatus showModal={showStatusModal} setShowModal={setStatusModel} selectedData={selectedData} />
        </>
    );
};

export default PendingInfluencer;
