import React from 'react';
import { Link } from 'react-router-dom';

const DmNavbar = () => {
    return (
        <>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#dmexecutive" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="dmexecutive">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Influencer</span>
                </a>
                <div className="collapse menu-dropdown" id="dmexecutive">
                    <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                        <Link to="/active-influencer" className="nav-link" data-key="t-analytics">Active Influencer </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/assigned-influencer" className="nav-link" data-key="t-ecommerce">Assigned Influencer</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/pending-influencer" className="nav-link" data-key="t-analytics">Pending Influencer  </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/inactive-influencer" className="nav-link" data-key="t-crm">Inactive Influencer </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/all-influencer" className="nav-link" data-key="t-ecommerce">All Influencer</Link>
                    </li>
                    </ul>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link menu-link" href="#clientinfreq" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="clientinfreq">
                    <i className="ri-rocket-line"></i>
                    <span data-key="t-clients">Influencer Request</span>
                </a>
                <div className="collapse menu-dropdown" id="clientinfreq">
                    <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                        <Link to="/client-sub-request-in-dm" className="nav-link" data-key="t-ecommerce">Influencer request List</Link>
                    </li>                    
                    </ul>
                </div>
            </li>
        </>
    );
}

export default DmNavbar;
