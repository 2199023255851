import React, { useState, useEffect } from 'react'
import Footer from '../components/footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../features/auth/authSlice';


const LayoutDefault = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, user, status, error } = useSelector((state) => state.auth);
  const [errors, setErrors] = useState("");
  const [errorData, setErrorData] = useState([]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };


  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (formData.rememberMe) {
      localStorage.setItem('loginData', JSON.stringify(formData));
    } else {
      localStorage.removeItem('loginData');
    }
    dispatch(loginUser({ email: formData.email, password: formData.password }))
      .unwrap()
      .then((result) => {
        if (result && result.data && result.data.jwt_token && result.status) {
          navigate('/dashboard');
        } else {
          setLoginError(result.message);
          setErrorData(result.form_err);
        }
      })
      .catch((err) => {
        setLoginError(err.message);
        setErrorData(err.form_err || []);
      });
    setFormData((prevData) => ({
      ...prevData,
      password: '',
    }));
  };


  useEffect(() => {
    if (user && user.data && user.data.jwt_token && user.status) {
      navigate('/dashboard');
    }
  }, [loginError, user, navigate]);


  const mapErrors = (errorData) => {
    const errorMap = {};
    errorData.forEach((error) => {
      if (!errorMap[error.param]) {
        errorMap[error.param] = error.msg;
      }
    });
    return errorMap;
  };

  useEffect(() => {

    const errorsValue = mapErrors(errorData);
    setErrors(errorsValue);
  }, [errorData]);
  return (
    <>
      <div
        className="login-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
      >
        <div className="bg-overlay">
          <div className="login-page-content overflow-hidden pt-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card overflow-hidden">
                    <div className="row g-0">
                      <div className="col-lg-6">
                        <div className="p-lg-5 p-4 auth-one-bg h-100">
                          <div className="bg-overlay"></div>
                          <div className="position-relative h-100 d-flex flex-column">
                            <div className="mb-4">
                              <Link className="d-block" to="/">
                                <img src="assets/images/logo-light.png" alt="" height="50" />
                              </Link>

                            </div>
                            <div className="mt-auto">
                              <div className="mb-3">
                                <i className="ri-double-quotes-l display-4 text-success"></i>
                              </div>

                              <div id="qoutescarouselIndicators" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                  <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0"
                                    className="active" aria-current="true" aria-label="Slide 1"></button>
                                  <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1"
                                    aria-label="Slide 2"></button>
                                  <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2"
                                    aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner text-center text-white-50 pb-5">
                                  <div className="carousel-item active">
                                    <p className="fs-15 fst-italic">
                                      " Influencer Act Yields, the Best of the Best
                                      "
                                    </p>
                                  </div>
                                  <div className="carousel-item">
                                    <p className="fs-15 fst-italic">
                                      " Unshackle the Power of your Brand, with
                                      Influencer Marketing "
                                    </p>
                                  </div>
                                  <div className="carousel-item">
                                    <p className="fs-15 fst-italic">
                                      " Analytical Strategies Leads, to Deliver
                                      Commendable Results "
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="login-form">
                          <div>
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p className="text-muted">
                              Sign in to continue to Influencer Act CRM.
                            </p>
                          </div>

                          <div className="mt-4">
                            <form onSubmit={handleLoginSubmit}>
                              <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  placeholder="Enter username"
                                  value={formData.email}
                                  onChange={handleChange}
                                />
                                <div className="text-danger">
                                  {errors.email && <p>{errors.email}</p>}
                                </div>
                              </div>

                              <div className="mb-3">
                                <label className="form-label" htmlFor="password-input">Password</label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control pe-5 password-input"
                                    name="password"
                                    placeholder="Enter password"
                                    value={formData.password}
                                    onChange={handleChange}
                                  />
                                  <button
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    onClick={handleTogglePassword}
                                  >
                                    <i className={`ri-${showPassword ? 'eye-fill' : 'eye-off-fill'} align-middle`}></i>
                                  </button>
                                  <div className="text-danger">
                                    {errors.password && <p>{errors.password}</p>}
                                  </div>
                                </div>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="rememberMe"
                                  checked={formData.rememberMe}
                                  onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                              </div>

                              <div className="mt-4">

                                <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                <button className="btn btn-success w-100" type="submit" disabled={loading}>
                                  Sign In
                                </button>
                                {error && <p>{error}</p>}
                              </div>
                              <div className='row'>
                                <div className="mt-4 col-sm-6">
                                  <Link className="btn btn-success w-100" to="/sign-up">Influencer Signup</Link>

                                </div>
                                <div className="mt-4 col-sm-6">
                                  <Link className="btn btn-success w-100" to="/client-sign-up">Client Signup</Link>

                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default LayoutDefault
