import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal'; 
import Button from 'react-bootstrap/Button'; 
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../features/auth/authSlice";
import { fetchCategoryData, fetchLanguageData } from "../../features/commonSlice/commonSlice";
// import jwt from 'jsonwebtoken';

const budgetData = [
    { label: "0-5Lacs", value: 1 },
    { label: "6-10Lacs", value: 2 },
    { label: "11-15Lacs", value: 3 },
    { label: "16-20Lacs", value: 4 },
    { label: "21-50Lacs", value: 5 },
    { label: "Above 50Lacs", value: 6 },
];

const UpdateInfluencer = (props) => {
    const { showModal, setShowModal, selectedData } = props;

    const [formData, setFormData] = useState({
        name: "",
        concernName: "",
        phoneNo: "",
        email: "",
        password: "",
        confirmPassword: "",
        categories: [],
        languages: [],
        budget: "",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categoryData, languageData, status: commonStatus } = useSelector((state) => state.influencer);

    const [langList, setLangList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        if (selectedData) {
            setFormData({ 
                name: selectedData?.name || "",
                concernName: selectedData?.concern_person_name || "",
                phoneNo: selectedData?.mobile_no || "",
                email: selectedData?.email || "",
                password: selectedData?.password  || "",
                confirmPassword: selectedData?.password || "",
                categories: selectedData?.category?.map(cat => ({ label: cat.label, value: cat.value })) || [],
                languages: selectedData?.language?.map(lang => ({ label: lang.label, value: lang.value })) || [],
                budget: budgetData.find(budget => budget.label === selectedData?.budget) || "",
            });
        }
    }, [selectedData]);

    useEffect(() => {
        if (commonStatus === 'idle') {
            dispatch(fetchCategoryData());
            dispatch(fetchLanguageData());
        }
    }, [commonStatus, dispatch]);

    useEffect(() => {
        if (commonStatus === 'succeeded') {
            setCategoryList(categoryData?.data || []);
            setLangList(languageData?.data || []);
        }
    }, [commonStatus, categoryData, languageData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            console.log("Update data ==>", formData);
            // Perform the update operation here, e.g., dispatching an action to update the client data
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
            if (!errorMap[error.param]) {
                errorMap[error.param] = error.msg;
            }
        });
        return errorMap;
    };

    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
    }, [errorData]);

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Update Influencer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="p-0">                                
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="name" className="form-label">
                                                          Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        placeholder="Please   Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                    <div className="text-danger"> {errors.name && <> {errors.name}</>}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="concernName" className="form-label">
                                                        Concern Person Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="concernName"
                                                        placeholder="Enter Concern Person Name"
                                                        value={formData.concernName}
                                                        onChange={handleChange}
                                                    />
                                                    <div className="text-danger"> {errors.concernName && <> {errors.concernName}</>}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="phoneNo" className="form-label">
                                                        Phone no <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="phoneNo"
                                                        placeholder="Enter Phone no"
                                                        value={formData.phoneNo}
                                                        onChange={handleChange}
                                                        maxLength={10}
                                                    />
                                                    <div className="text-danger"> {errors.phoneNo && <> {errors.phoneNo}</>}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Enter email address"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    <div className="text-danger"> {errors.email && <> {errors.email}</>}</div>
                                                </div>
                                            </div>
                                            {/*}
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="password" className="form-label">
                                                        Enter Password <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        placeholder="Enter password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                          npm start          <div className="text-danger"> {errors.password && <> {errors.password}</>}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="confirmPassword" className="form-label">
                                                        Enter Confirm Password <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="confirmPassword"
                                                        placeholder="Enter Confirm password"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                    <div className="text-danger"> {errors.confirmPassword && <> {errors.confirmPassword}</>}</div>
                                                </div>
                                            </div>
                                              {*/}
                                         {/*}   <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="categories-select" className="form-label mb-2">
                                                        Categories <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        name="categories"
                                                        options={categoryList}
                                                        classNamePrefix="select"
                                                        onChange={(selected) => handleSelectChange("categories", selected)}
                                                        value={formData.categories}
                                                    />
                                                    <div className="text-danger"> {errors.categories && <> {errors.categories}</>}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="languages-select" className="form-label">
                                                        Select Language <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        name="languages"
                                                        options={langList}
                                                        classNamePrefix="select"
                                                        onChange={(selected) => handleSelectChange("languages", selected)}
                                                        value={formData.languages}
                                                    />
                                                    <div className="text-danger"> {errors.languages && <> {errors.languages}</>}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="budget-select" className="form-label">
                                                        Select Budget <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        name="budget"
                                                        options={budgetData}
                                                        classNamePrefix="select"
                                                        onChange={(selected) => handleSelectChange("budget", selected)}
                                                        value={formData.budget}
                                                    />
                                                    <div className="text-danger"> {errors.budget && <> {errors.budget}</>}</div>
                                                </div>
                                            </div> {*/}
                                        </div>
                                        <div className="mb-4">
                                            <p className="mb-0 fs-12 text-muted fst-italic">
                                                By registering you agree to the Influencer Act{" "}
                                                <a
                                                    href="#"
                                                    className="text-primary text-decoration-underline fst-normal fw-medium"
                                                >
                                                    Terms of Use
                                                </a>
                                            </p>
                                        </div> 
                                        <p className='text-danger text-center'>{!errorData.length > 0 && loginError}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button type="button" variant="primary" onClick={handleSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UpdateInfluencer;
