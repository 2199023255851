import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../features/auth/authSlice";
import { toast } from "react-toastify";
const ChangePassword = (props) => {
    const { showModal, setShowModal } = props;
    const [errors, setErrors] = useState('')
    const [errorData, setErrorData] = useState([]);
    const [loginError, setLoginError] = useState('');
    const decodedToken = useDecodedToken();
    const [oldPassword, setoldShowPassword] = useState(false);
    const [newPassword, setnewShowPassword] = useState(false);
    const [conPassword, setconShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: "",
        user_id: ""
     });
    const handleChange = (e) => {
        const { name, value } = e.target;
           setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: value
           }));
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        try {
           const clientData = {
            user_id: decodedToken?.UsrId,
            old_password: formData.old_password,
            new_password: formData.new_password,
            confirm_password: formData.confirm_password,
              
           };
        
           dispatch(changePassword(clientData))
            .unwrap()
            .then((result) => {
               console.log(result);
                if (result.status) {
                    toast.success(result.message, {
                       onClose: () => {
                       // setShowModal(false)
                       },
                    });
                    setFormData({
                        old_password: "",
                        new_password: "",
                        confirm_password: "",
                       
                    });
                } else {
 
                    setLoginError(result.message);
                    setErrorData(result.form_err);
                 }
            })
            .catch((err) => {
                 setLoginError(err.message);
                 setErrorData(err.form_err || []);
                 toast.error(err.message);
            });
              
               
        } catch (error) {
           console.error("An error occurred:", error);
        } 
    };

     const mapErrors = (errorData) => {
        const errorMap = {};
        errorData.forEach((error) => {
           if (!errorMap[error.param]) {
              errorMap[error.param] = error.msg;
           }
        });
        return errorMap;
     };
    useEffect(() => {
        const errorsValue = mapErrors(errorData);
        setErrors(errorsValue);
     }, [errorData]);

     const closeModal = () => {
       // setShowModal(false);
    };

    const handleTogglePassword = () => {
      setoldShowPassword(!oldPassword);
    };
    const handleToggleNewPassword = () => {
      setnewShowPassword(!newPassword);
    };
    const handleToggleConPassword = () => {
      setconShowPassword(!conPassword);
    };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-6">
              <h4>Change Password</h4>
            </div> 
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-6">
          <div className="card">
            <div className="card-body">
              <div className="p-0">
                <table className="table table-striped table-bordered">
                  <tbody>
                    <tr>
                      <td className="w-25 p-3">  Old Password:  </td>
                      <td > 
                        <div className="position-relative auth-pass-inputgroup mb-3"> 
                            <input
                                type={oldPassword ? "text" : "password"}
                                className="form-control pe-5 password-input"
                                name="old_password"
                                placeholder="Enter Old Password    "
                                value={formData.old_password}
                                onChange={handleChange}
                            />
                            <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                type="button"
                                onClick={handleTogglePassword}
                              >
                              <i className={`ri-${oldPassword ? 'eye-off-fill' : 'eye-fill'} align-middle`}></i>
                            </button>
                             <div className="text-danger"> {errors.old_password && <> {errors.old_password}</>}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">New Password:</td>
                      <td > 
                        <div className="position-relative auth-pass-inputgroup mb-3"> 
                          <input
                              type={newPassword ? "text" : "password"}
                              className="form-control pe-5 password-input"
                              name="new_password"
                              placeholder="Enter New Password "
                              value={formData.new_password}
                              onChange={handleChange}
                          />
                          <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              type="button"
                              onClick={handleToggleNewPassword}
                            >
                            <i className={`ri-${newPassword ? 'eye-off-fill' : 'eye-fill'} align-middle`}></i>
                          </button>
                            
                            <div className="text-danger"> {errors.new_password && <> {errors.new_password}</>}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-25 p-3">Confirm Password:</td>
                      <td > 
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                              type={conPassword ? "text" : "password"}
                              className="form-control pe-5 password-input"
                              name="confirm_password"
                              placeholder="Enter Confirm Password"
                              value={formData.confirm_password}
                              onChange={handleChange}
                          />
                          <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              type="button"
                              onClick={handleToggleConPassword}
                            >
                            <i className={`ri-${conPassword ? 'eye-off-fill' : 'eye-fill'} align-middle`}></i>
                          </button>
                            
                            <div className="text-danger"> {errors.confirm_password && <> {errors.confirm_password}</>}</div>
                        </div>
                         </td>
                    </tr>
                    <tr>
                       
                      <td colSpan={2}><Button type="button" variant="primary" onClick={handleSubmit} >
                        Update
                    </Button></td>
                    </tr>
                
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
