import axios from 'axios';
// import { store } from '../app/store';
import { decodeToken } from '../features/utils/decodeToken';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});
axiosInstance.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('token')) || process.env.REACT_APP_COMMON_TOKEN;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
