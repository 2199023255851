import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientRequestData } from "../../features/clientInfluencerRequestSlice/clientInfluencerRequestSlice";
import { Button, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import Select from "react-select";
import EnquireInfluencersRequest from "./EnquireInfluencersRequest";
import useDecodedToken from "../../features/utils/hooks/useDecodedToken";
import {formatDate} from "../../utils/dateUtils";
import {formatAmount} from "../../utils/amountFormat";
import moment from "moment";
export const headerData = {
  sr_no: "S. No.",
  action: "Action",
  status: "Status",
  requestId: "Requested ID",
  product: "Product",
  no_of_influencer: "No of influencers required ",
  budget: "Budget",
  requestedDate: "Requested Date",
  lastUpdatedOn: "Last Updated On",
};

const ApprovedInfluencers = () => {
  const dispatch = useDispatch();
  const decodedToken = useDecodedToken();
  const { clientDataRequest, status, error } = useSelector(
    (state) => state.influencerRequest
  );
  const [clientsPerPage, setClientsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsRequestData, setClientsRequestData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const [showEnqueryModal, setShowEnqueryModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    if (decodedToken) {
      dispatch(
        fetchClientRequestData({
          client_id: decodedToken?.UsrId,
          page: currentPage,
          limit: clientsPerPage === 2000 ? "All" : clientsPerPage,
        })
      );
    }
  }, [dispatch, decodedToken, currentPage, clientsPerPage]);

  useEffect(() => {
    if (status === "succeeded") {
      setClientsRequestData(clientDataRequest?.data || []);
    }
  }, [status, clientDataRequest]);

  useEffect(() => {
    console.log("clientDataRequest =123=>", clientDataRequest);
  }, [clientDataRequest]);

  // Sorting logic
  const sortTable = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Apply sorting
  const sortedData = [...clientsRequestData].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (sortConfig.direction === "ascending") {
      return aValue.toString().localeCompare(bValue.toString());
    }
    return bValue.toString().localeCompare(aValue.toString());
  });

  const clickEnquery = () => {
    setShowEnqueryModal(true);
  };

  const handleUpdateClient = (rowData) => {
    setShowUpdateModal(true);
    setSelectedData(rowData);
  };

  const handleDetailClient = (rowData) => {
    setShowDetailModal(true);
    setSelectedData(rowData);
  };

  const handleClientsPerPageChange = (selectedOption) => {
    setClientsPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to first page whenever per page value changes
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const perPageOptions = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 500, label: "500" },
    { value: "2000", label: "All" }, // Add 'All' option
  ];

 

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">All Request</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">CRM</a>
                </li>
                <li className="breadcrumb-item active">All Request</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card" id="leadsList">
            <div className="card-header border-0">
              <div className="row g-4 align-items-center">
                <div className="col-sm-1">
                  <label className="small">Select Records</label>
                  <Select
                    className="basic-single small"
                    classNamePrefix="select"
                    name="perPage"
                    options={perPageOptions}
                    defaultValue={perPageOptions[0]}
                    onChange={handleClientsPerPageChange}
                  />
                </div>
                <div className="col-sm-auto ms-auto">
                  <div className="hstack gap-2">
                    <button
                      type="button"
                      className="btn btn-info"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasExample"
                    >
                      <i className="ri-filter-3-line align-bottom me-1"></i>{" "}
                      Filters
                    </button>
                    <Button
                      className="btn btn-success add-btn"
                      onClick={clickEnquery}
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Add
                      Enquiry
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body mt-0 pt-0">
              <div className="table-responsive">
                <table className="table table-bordered table-striped align-middle">
                  <thead>
                    <tr>
                      <th>{headerData.sr_no}</th>
                      <th>{headerData.action}</th>
                      <th>{headerData.status}</th>
                      <th onClick={() => sortTable("requestId")}>{headerData.requestId}</th>
                      <th>{headerData.product}</th>
                      <th>{headerData.no_of_influencer}</th>
                      <th>{headerData.budget}</th>
                      <th>{headerData.requestedDate}</th>
                      <th>{headerData.lastUpdatedOn}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.length > 0 ? (
                      sortedData.map((dataActive, index) => {
                        const productArray = dataActive.product.split(',').map(item => item.trim());
                        const visibleProducts   = productArray.slice(0, 2);
                        const createdAt = new Date(dataActive.created_at);
                        const updatedAt = new Date(dataActive.updated_at);

                        // Log date values to check their format
                        console.log('created_at:', dataActive.created_at, 'parsed:', createdAt);
                        console.log('updated_at:', dataActive.updated_at, 'parsed:', updatedAt);

                        return (
                          <tr key={index}>
                            <td>
                              {clientsPerPage === "All"
                                ? index + 1
                                : (currentPage - 1) * clientsPerPage + index + 1}
                            </td>
                            <td>
                              <ul className="list-inline hstack mb-0">
                                <li className="list-inline-item" >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        View
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn btn-sm btn-info edit-item-btn"
                                      onClick={() => handleDetailClient(dataActive)}
                                    >
                                    <i className="ri-eye-fill align-bottom"></i>
                                  </Button>
                                  </OverlayTrigger>
                                </li>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Update
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className="btn btn-sm btn-warning edit-item-btn"
                                    onClick={() => handleUpdateClient(dataActive)}
                                  >
                                    <i className="ri-edit-fill align-bottom"></i>
                                  </Button>
                                </OverlayTrigger>
                              </ul>
                            </td> 
                            <td>
                              <span
                                className={`badge  ${
                                  dataActive.client_status === "Sent to DM"
                                    ? "bg-success-subtle text-success"
                                    : dataActive.client_status === "Assigned"
                                    ? "bg-info-subtle text-info"
                                    : dataActive.client_status === "Inactive"
                                    ? "bg-danger-subtle text-danger"
                                    : dataActive.client_status === "Pending" &&
                                      "bg-warning-subtle text-warning"
                                }`}
                              >
                             
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {dataActive.client_status}
                                    </Tooltip>
                                  }
                                >
                                  <button
                                  
                                    className="btn btn-default btn-sm m-0 p-0"
                                  >
                                    {dataActive.client_status}{" "}
                                  </button>
                                </OverlayTrigger>
                            
                              </span>
                            </td> 
                            
                            <td>
                              <span
                                  className={`badge  bg-info-subtle text-info `}
                              >
                                  
                                  <OverlayTrigger
                                  placement="top"
                                  overlay={
                                      <Tooltip id="tooltip-top">
                                      View Request
                                      </Tooltip>
                                  }
                                  >
                                  <button
                                      className="btn btn-default btn-sm m-0 p-0"
                                     
                                  >
                                      {dataActive.req_code}
                                  </button>
                                  </OverlayTrigger>
                              
                              </span>
                            </td>
                            <td>        
                                    <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top"> 
                                          {productArray.join(', ')}
                                        </Tooltip>
                                    }
                                    >
                                    <button
                                        className="btn btn-default btn-sm m-0 p-0"
                                    >
                                        {visibleProducts.join(', ')}
                                        {' ...'}
                                    </button>
                                    </OverlayTrigger>
                            </td>
                            <td>{dataActive.no_of_influencer}</td>
                            <td>{formatAmount(dataActive.budget)}</td>
                            <td>
                              {isNaN(createdAt.getTime())
                                ? 'Invalid Date'
                                :    formatDate(dataActive.created_at)}
                            </td>
                            <td>
                              {isNaN(updatedAt.getTime())
                                ? 'Invalid Date'
                                :   formatDate(dataActive.updated_at)}
                               
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row">
                {clientsPerPage !== "2000" && (
                  <>
                    <div className="col-sm-6">
                      Showing{" "}
                      {Math.min(
                        (currentPage - 1) * clientsPerPage + 1,
                        clientDataRequest.pagination.totalItems
                      )}{" "}
                      to{" "}
                      {Math.min(
                        currentPage * clientsPerPage,
                        clientDataRequest.pagination.totalItems
                      )}{" "}
                      of {clientDataRequest.pagination.totalItems} entries
                    </div>
                    <div className="col-sm-6">
                      <Pagination className="justify-content-end">
                        <Pagination.Prev
                          onClick={() =>
                            setCurrentPage(
                              currentPage > 1 ? currentPage - 1 : currentPage
                            )
                          }
                          disabled={currentPage === 1}
                        />
                        {Array.from(
                          { length: clientDataRequest.pagination.totalPages },
                          (_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          )
                        )}
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage(
                              currentPage <
                                clientDataRequest.pagination.totalPages
                                ? currentPage + 1
                                : currentPage
                            )
                          }
                          disabled={
                            currentPage ===
                            clientDataRequest.pagination.totalPages
                          }
                        />
                      </Pagination>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EnquireInfluencersRequest
        showModal={showEnqueryModal}
        setShowModal={setShowEnqueryModal}
        decodedToken={decodedToken}
      />
    </>
  );
};

export default ApprovedInfluencers;
