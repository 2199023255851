/** @format */

import React, { useEffect, useState } from "react";
import Select from "react-select";
import BackgroundImg from "../../layout/BackgroundImg";
import Logo from "../../layout/Logo";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoryData,
  fetchChannelTypeData,
  fetchCityData,
  fetchGenreData,
  fetchLanguageData,
  fetchStateData
} from "../../features/commonSlice/commonSlice";
import { registerUser } from "../../features/auth/authSlice";
import { parseNumber } from "../../utils/formatNumber";
import FormattedInput from "../common/FormattedInput";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    categoryData,
    languageData,
    stateData,
    cityData,
    channelTypeData,
    genreData,
    status: commonStatus
  } = useSelector((state) => state.influencer);

  const [isChecked, setIsChecked] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [channelList, setChannelList] = useState([]);

  const [errors, setErrors] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [loginError, setLoginError] = useState("");

  const [formData, setFormData] = useState({
    influencer_name: "",
    concern_name: "",
    mobile_number: null,
    email: "",
    pan_no: null,
    gst: null,
    channel_name: "",
    channel_type: [],
    state: "",
    city: "",
    below_channels: {
      youTube_status: "",
      youTube: {
        youTube_URL: "",
        genre: "",
        subscribers: "",
        language: "",
        average_Views: "",
        dedicated_Video_Cost: "",
        integrated_Video_Cost: "",
        conceptual_Cost: ""
      },
      facebook_status: "",
      facebook: {
        facebook_URL: "",
        genre: "",
        followers: "",
        video_Cost: ""
      },
      instagram_status: "",
      instagram: {
        instagram_URL: "",
        followers: "",
        video_Cost: ""
      },
      twitter_X_status: "",
      twitter_X: {
        twitter_URL: "",
        followers: "",
        video_Cost: ""
      },
      linkedIn_status: "",
      linkedIn: {
        linkedIn_URL: "",
        connections: "",
        video_Cost: ""
      },
      whatsapp_status: "",
      whatsapp: {
        whatsapp_Channel_URL: "",
        followers: "",
        video_Cost: ""
      },
      telegram_status: "",
      telegram: {
        telegram_URL: "",
        followers: "",
        video_Cost: ""
      },
      threads_status: "",
      threads: {
        threads_URL: "",
        followers: "",
        video_Cost: ""
      }
    },
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    dispatch(fetchCategoryData());
    dispatch(fetchLanguageData());
    dispatch(fetchStateData());
    dispatch(fetchChannelTypeData());
    dispatch(fetchGenreData());
  }, []);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setCategoryList(categoryData);
      setLangList(languageData);
      setStateList(stateData);
      setChannelList(channelTypeData);
      setGenreList(genreData);
    }
  }, [
    commonStatus,
    categoryData,
    languageData,
    stateData,
    channelTypeData,
    genreData
  ]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Determine if the field should be parsed as a number
    const shouldParseAsNumber = ["mobile_number", "followers", "subscribers","dedicated_Video_Cost","integrated_Video_Cost","conceptual_Cost","video_Cost","connections"].includes(name.split(".").pop());

    const parsedValue = shouldParseAsNumber ? parseNumber(value) : value;

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let field = newFormData;
      const keys = name.split(".");

      keys.slice(0, -1).forEach((key) => {
        if (!field[key]) {
          field[key] = {};
        }
        field = field[key];
      });

      field[keys[keys.length - 1]] = parsedValue;

      return newFormData;
    });
  };

  // Example parseNumber function to prevent NaN
  const parseNumber = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? "" : parsed;
  };

  useEffect(() => {
    console.log("test ==>", formData);

  }, [formData])


  const handleSelectChange1 = (name, selectedOption) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let field = newFormData;
      const keys = name.split(".");
      keys.slice(0, -1).forEach((key) => {
        if (!field[key]) {
          field[key] = {};
        }
        field = field[key];
      });
      field[keys[keys.length - 1]] = selectedOption;
      return newFormData;
    });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const influencerData = {
        influencer_status: isChecked ? "1" : "4",
        name: formData.influencer_name,
        concern_name: formData.concern_name,
        phone_no: formData.mobile_number,
        email: formData.email,
        pan_no: formData.pan_no,
        gst: formData.gst,
        channel_name: formData.channel_name,
        channel_type: formData.channel_type,
        state: formData.state,
        city: formData.city,
        below_channels: {
          youTube_status: formData.below_channels.youTube_status ? "1" : "0",
          youTube: formData.below_channels.youTube,
          facebook_status: formData.below_channels.facebook_status ? "1" : "0",
          facebook: formData.below_channels.facebook,
          instagram_status: formData.below_channels.instagram_status
            ? "1"
            : "0",
          instagram: formData.below_channels.instagram,
          twitter_X_status: formData.below_channels.twitter_X_status
            ? "1"
            : "0",
          twitter_X: formData.below_channels.twitter_X,
          linkedIn_status: formData.below_channels.linkedIn_status ? "1" : "0",
          linkedIn: formData.below_channels.linkedIn,
          whatsapp_status: formData.below_channels.whatsapp_status ? "1" : "0",
          whatsapp: formData.below_channels.whatsapp,
          telegram_status: formData.below_channels.telegram_status ? "1" : "0",
          telegram: formData.below_channels.telegram,
          threads_status: formData.below_channels.threads_status ? "1" : "0",
          threads: formData.below_channels.threads
        }
      };
      dispatch(registerUser(influencerData))
        .unwrap()
        .then((result) => {
          if (result.status) {
            toast.success(result.message, {
              onClose: () => {
                navigate("/");
              }
            });
            setFormData({
              influencer_name: "",
              concern_name: "",
              mobile_number: null,
              email: "",
              pan_no: null,
              gst: null,
              channel_name: "",
              channel_type: [],
              state: "",
              city: "",
              below_channels: {
                youTube_status: "",
                youTube: {
                  youTube_URL: "",
                  genre: "",
                  subscribers: "",
                  language: "",
                  average_Views: "",
                  dedicated_Video_Cost: "",
                  integrated_Video_Cost: "",
                  conceptual_Cost: ""
                },
                facebook_status: "",
                facebook: {
                  facebook_URL: "",
                  genre: "",
                  followers: "",
                  video_Cost: ""
                },
                instagram_status: "",
                instagram: {
                  instagram_URL: "",
                  followers: "",
                  video_Cost: ""
                },
                twitter_X_status: "",
                twitter_X: {
                  twitter_URL: "",
                  followers: "",
                  video_Cost: ""
                },
                linkedIn_status: "",
                linkedIn: {
                  linkedIn_URL: "",
                  connections: "",
                  video_Cost: ""
                },
                whatsapp_status: "",
                whatsapp: {
                  whatsapp_Channel_URL: "",
                  followers: "",
                  video_Cost: ""
                },
                telegram_status: "",
                telegram: {
                  telegram_URL: "",
                  followers: "",
                  video_Cost: ""
                },
                threads_status: "",
                threads: {
                  threads_URL: "",
                  followers: "",
                  video_Cost: ""
                }
              },
              password: "",
              confirmPassword: ""
            });
          } else {
            setLoginError(result.message);
            setErrorData(result.form_err);
          }
        })
        .catch((err) => {
          setLoginError(err.message);
          setErrorData(err.form_err || []);
          toast.error(err.message);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const mapErrors = (errorData) => {
    const errorMap = {};
    errorData.forEach((error) => {
      if (!errorMap[error.param]) {
        // errorMap[error.param] = error.msg;
        const path = error.param.split(".").join("_");
        errorMap[path] = error.msg;
      }
    });
    return errorMap;
  };
  console.log("name ALLCHANNEL:", errors.below_channels_youTube);
  useEffect(() => {
    const errorsValue = mapErrors(errorData);
    setErrors(errorsValue);
  }, [errorData]);

  const handleRadioChange = (event) => {
    setIsChecked(event.target.value === "active");
  };
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      below_channels: {
        ...prevState.below_channels,
        [name]: checked
      }
    }));
  };

  const handleSelectChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
    if (name === "state") {
      const stateId = value.value;
      const data = {
        state_id: stateId
      };
      dispatch(fetchCityData(data));
    }
  };

  useEffect(() => {
    if (formData.state) {
      setCityList(cityData);
    }
  }, [cityData]);



  return (
    <>
      <div className="auth-page-wrapper pt-5">
        <BackgroundImg />
        <div className="auth-page-content">
          <div className="container">
            <Logo />
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="p-2 mt-4">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="radio"
                              id="active"
                              name="status"
                              value="active"
                              checked={isChecked}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="active"> Active</label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <input
                              type="radio"
                              id="inactive"
                              name="status"
                              value="inactive"
                              checked={!isChecked}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="inactive"> Inactive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <>
                        <div className="row">
                          <div className="mt-2">
                            <h5 className="text-primary">Personal Details</h5>
                          </div>
                          {isChecked && (
                            <>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="username" className="form-label">
                                    Name <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="influencer_name"
                                    placeholder="Please Enter Name"
                                    value={formData.influencer_name}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <div className="text-danger">
                                    {errors.name && <p>{errors.name}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="concernname"
                                    className="form-label"
                                  >
                                    Concern Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="concern_name"
                                    placeholder="Please Enter Name"
                                    value={formData.concern_name}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <div className="text-danger">
                                    {errors.concern_name && (
                                      <> {errors.concern_name}</>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="phoneno" className="form-label">
                                    Phone no{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="mobile_number"
                                    placeholder="Please enter only numeric values"
                                    value={formData.mobile_number}
                                    onChange={handleInputChange}
                                    required
                                  />

                                  <div className="text-danger">
                                    {errors.phone_no && (
                                      <p>{errors.phone_no}</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {!isChecked && (
                            <div className="col-lg-4 mb-3">
                              <div>
                                <label for="video-field" className="form-label">
                                  Channel Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="channel_name"
                                  placeholder="Enter channel name"
                                  value={formData.channel_name}
                                  onChange={handleInputChange}
                                  required
                                />
                                <div className="text-danger">
                                  {errors.channel_name && (
                                    <p>{errors.channel_name}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            className={
                              !isChecked ? "col-lg-4 mb-3" : "col-md-6"
                            }
                          >
                            <div className="mb-3">
                              <label for="useremail" className="form-label">
                                Email <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Enter email address"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.email && <p>{errors.email}</p>}
                              </div>
                            </div>
                          </div>
                          {isChecked && (
                            <>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="pan" className="form-label">
                                    PAN no{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="pan_no"
                                    placeholder="Enter PAN no"
                                    value={formData.pan_no}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <div className="text-danger">
                                    {errors.pan_no && <p>{errors.pan_no}</p>}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="gst" className="form-label">
                                    GST{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="gst"
                                    placeholder="Enter GST no"
                                    value={formData.gst}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <div className="text-danger">
                                    {errors.gst && <p>{errors.gst}</p>}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={
                              !isChecked ? "col-lg-4 mb-3" : "col-lg-6 mb-4"
                            }
                          >
                            <div>
                              <label
                                for="video-type-field"
                                className="form-label"
                              >
                                Channel type{" "}
                              </label>
                              <Select
                                defaultValue="Select Categories"
                                isMulti
                                name="channel_type"
                                options={channelList?.data}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange("channel_type", selected)
                                }
                                value={formData.channel_type}
                              />
                              <div className="text-danger">
                                {errors.channel_type && (
                                  <p>{errors.channel_type}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {isChecked && (
                            <>
                              <div className="mt-2">
                                <h5 className="text-primary">Location</h5>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="state" className="form-label">
                                    State{" "}
                                  </label>
                                  <Select
                                    name="state"
                                    options={stateList?.data} // Adjust this mapping according to your state object structure
                                    classNamePrefix="select"
                                    onChange={(selected) =>
                                      handleSelectChange("state", selected)
                                    }
                                    value={formData.state}
                                  />
                                  <div className="invalid-feedback">
                                    Please select state
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="city" className="form-label">
                                    City{" "}
                                  </label>
                                  <Select
                                    name="city"
                                    options={cityList?.data} // Adjust this mapping according to your state object structure
                                    classNamePrefix="select"
                                    onChange={(selected) =>
                                      handleSelectChange("city", selected)
                                    }
                                    value={formData.city}
                                  />
                                  <div className="text-danger">
                                    {errors.city && <p>{errors.city}</p>}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                      <hr />

                      <div className="row mt-2 g-3">
                        <label>Select Below Channels</label>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="youTube_status"
                            checked={formData.below_channels.youTube_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="youtubeCheckbox"
                          >
                            {" "}
                            YouTube
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="facebook_status"
                            checked={formData.below_channels.facebook_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="facebookCheckbox"
                          >
                            {" "}
                            Facebook
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="instagram_status"
                            checked={formData.below_channels.instagram_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="instagramCheckbox"
                          >
                            {" "}
                            Instagram
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="twitter_X_status"
                            checked={formData.below_channels.twitter_X_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="twitterCheckbox"
                          >
                            {" "}
                            Twitter (X)
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="linkedIn_status"
                            checked={formData.below_channels.linkedIn_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="linkedinCheckbox"
                          >
                            {" "}
                            LinkedIn
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="whatsapp_status"
                            checked={formData.below_channels.whatsapp_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="whatsappCheckbox"
                          >
                            {" "}
                            Whatsapp
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="telegram_status"
                            checked={formData.below_channels.telegram_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="telegramCheckbox"
                          >
                            {" "}
                            Telegram
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="threads_status"
                            checked={formData.below_channels.threads_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="threadsCheckbox"
                          >
                            {" "}
                            Threads
                          </label>
                        </div>

                        <div className="text-danger">
                          {errors.below_channels && (
                            <p>{errors.below_channels}</p>
                          )}
                        </div>
                      </div>
                      {formData.below_channels.youTube_status && (
                        <div id="youtubeInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">You Tube</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="youtubeUrl">
                                You Tube URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.youTube.youTube_URL"
                                placeholder="Enter youTube URL"
                                value={
                                  formData.below_channels.youTube.youTube_URL
                                }
                                onChange={handleInputChange}
                                required
                              />

                              {/* <input type="text" className="form-control" name="yturl" id="youtubeUrl" value="" oninput="checkYoutube();" /> */}
                              <div className="text-danger">
                                {errors.below_channels_youTube_youTube_URL && (
                                  <p>
                                    {errors.below_channels_youTube_youTube_URL}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="ygenere">
                                Genre<span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="below_channels.youTube.youTube_URL"
                                options={genreList?.data?.map((genre) => ({
                                  value: genre.value,
                                  label: genre.label
                                }))}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange1(
                                    "below_channels.youTube.genre",
                                    selected
                                  )
                                }
                                value={formData.below_channels.youTube.genre}
                              />
                              <div className="text-danger">
                                {errors.below_channels_youTube_genre && (
                                  <p>{errors.below_channels_youTube_genre}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="subscribers">
                                Subscribers
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.youTube.subscribers"
                                placeholder="Enter Subscribers"
                                value={
                                  formData.below_channels.youTube.subscribers
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_youTube_subscribers && (
                                  <p>
                                    {errors.below_channels_youTube_subscribers}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="language">
                                Language<span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="below_channels.youTube.language"
                                options={langList?.data?.map((lang) => ({
                                  value: lang.value,
                                  label: lang.label
                                }))}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange1(
                                    "below_channels.youTube.language",
                                    selected
                                  )
                                }
                                value={formData.below_channels.youTube.language}
                              />

                              <div className="text-danger">
                                {errors.below_channels_youTube_language && (
                                  <p>
                                    {errors.below_channels_youTube_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="avgViews">Average Views :</label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.youTube.average_Views"
                                placeholder="Enter Average Views"
                                value={
                                  formData.below_channels.youTube.average_Views
                                }
                                onChange={handleInputChange}
                                disabled
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="mt-2 mb-3">
                              <h5 className="text-primary">Video Cost s</h5>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label for="ygenere">
                                  Dedicated Video Cost:
                                </label>
                                <FormattedInput
                                  label="Dedicated Video Cost"
                                  name="below_channels.youTube.dedicated_Video_Cost"
                                  value={
                                    formData.below_channels.youTube
                                      .dedicated_Video_Cost
                                  }
                                  onChange={handleInputChange}
                                />

                                <div className="video_cost_disp p-1 text-white"> </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="mb-3">
                                <label for="ygenere">
                                  Integrated Video Cost:
                                </label>
                                <FormattedInput
                                  label="Integrated Video Cost"
                                  name="below_channels.youTube.integrated_Video_Cost"
                                  value={
                                    formData.below_channels.youTube
                                      .integrated_Video_Cost
                                  }
                                  onChange={handleInputChange}
                                />
                                <div className="video_cost_disp p-1 text-white" > </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label for="ygenere">
                                  Conceptual Cost:
                                </label>
                                <FormattedInput
                                  label="Conceptual Cost"
                                  name="below_channels.youTube.conceptual_Cost"
                                  value={
                                    formData.below_channels.youTube
                                      .conceptual_Cost
                                  }
                                  onChange={handleInputChange}
                                />
                                <div className="video_cost_disp p-1 text-white"></div>
                              </div>
                            </div>
                            <div className="text-danger">
                              {errors.below_channels_youTube && (
                                <p>{errors.below_channels_youTube}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.facebook_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Facebook</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="facebookUrl">
                                Facebook URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.facebook.facebook_URL"
                                placeholder="Enter Facebook URL"
                                value={
                                  formData.below_channels.facebook.facebook_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <p
                                className="validationMessage"
                                style={{ color: "black" }}
                              ></p>
                              <div className="text-danger">
                                {errors.below_channels_facebook_facebook_URL && (
                                  <p>
                                    {
                                      errors.below_channels_facebook_facebook_URL
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="fbgenere">
                                Genre<span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="below_channels.facebook.genre"
                                options={genreList.data.map((genre) => ({
                                  value: genre.value,
                                  label: genre.label
                                }))}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange1(
                                    "below_channels.facebook.genre",
                                    selected
                                  )
                                }
                                value={formData.below_channels.facebook.genre}
                              />
                              <div className="text-danger">
                                {errors.below_channels_facebook_genre && (
                                  <p>{errors.below_channels_facebook_genre}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="likes">
                                Followers<span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.facebook.followers"
                                placeholder="Enter Followers"
                                value={
                                  formData.below_channels.facebook.followers
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_facebook_followers && (
                                  <p>
                                    {errors.below_channels_facebook_followers}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-">
                              <div className="mb-3">
                                <label for="ygenere">
                                  Video Cost:
                                </label>
                                <FormattedInput
                                  label=" Video Cost"
                                  name="below_channels.facebook.video_Cost"
                                  placeholder="Enter Video Cost"
                                  value={
                                    formData.below_channels.facebook.video_Cost
                                  }
                                  onChange={handleInputChange}
                                />
                                <div className="video_cost_disp text-white"></div>
                                <div className="text-danger">
                                  {errors.below_channels_facebook_video_Cost && (
                                    <p>
                                      {
                                        errors.below_channels_facebook_video_Cost
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.instagram_status && (
                        <div id="instagramInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Instagram</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="instagramUrl">
                                Instagram URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.instagram.instagram_URL"
                                placeholder="Enter Instagram URL"
                                value={
                                  formData.below_channels.instagram
                                    .instagram_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <p
                                className="validationMessageinsta"
                                style={{ color: "black" }}
                              ></p>
                              <div className="text-danger">
                                {errors.below_channels_instagram_instagram_URL && (
                                  <p>
                                    {
                                      errors.below_channels_instagram_instagram_URL
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="followersInstagram">
                                Followers<span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.instagram.followers"
                                placeholder="Enter Followers"
                                value={
                                  formData.below_channels.instagram.followers
                                }
                                onChange={handleInputChange}
                                required
                              />

                              <div className="text-danger">
                                {errors.below_channels_instagram_followers && (
                                  <p>
                                    {errors.below_channels_instagram_followers}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="ygenere">
                                    Video Cost:
                                  </label>
                                  <FormattedInput
                                    label="Video Cost"
                                    name="below_channels.instagram.video_Cost"
                                    placeholder="Enter Video Cost"
                                    value={
                                      formData.below_channels.instagram
                                        .video_Cost
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <div className="video_cost_disp text-white"></div>
                                  <div className="text-danger">
                                    {errors.below_channels_instagram_video_Cost && (
                                      <p>
                                        {
                                          errors.below_channels_instagram_video_Cost
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.twitter_X_status && (
                        <div id="twitterInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Twitter (X)</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="instagramUrl">
                                Twitter URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.twitter_X.twitter_URL"
                                placeholder="Enter Instagram URL"
                                value={
                                  formData.below_channels.twitter_X.twitter_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <p
                                className="validationMessageinsta"
                                style={{ color: "black" }}
                              ></p>
                              <div className="text-danger">
                                {errors.below_channels_twitter_X_twitter_URL && (
                                  <p>
                                    {
                                      errors.below_channels_twitter_X_twitter_URL
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="followersInstagram">
                                Followers<span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.twitter_X.followers"
                                placeholder="Enter Followers"
                                value={
                                  formData.below_channels.twitter_X.followers
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_twitter_X_followers && (
                                  <p>
                                    {errors.below_channels_twitter_X_followers}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="ygenere">
                                    Video Cost:
                                  </label>
                                  <FormattedInput
                                    label="Video Cost"
                                    name="below_channels.twitter_X.video_Cost"
                                    placeholder="Enter Video Cost"
                                    value={
                                      formData.below_channels.twitter_X
                                        .video_Cost
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <div className="video_cost_disp text-white"></div>
                                  <div className="text-danger">
                                    {errors.below_channels_twitter_X_video_Cost && (
                                      <p>
                                        {
                                          errors.below_channels_twitter_X_video_Cost
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.linkedIn_status && (
                        <div id="linkedinInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Linkedin</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="linkedinUrl">
                                LinkedIn URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.linkedIn.linkedIn_URL"
                                placeholder="Enter Instagram URL"
                                value={
                                  formData.below_channels.linkedIn.linkedIn_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <p
                                className="validationMessagelink"
                                style={{ color: "black" }}
                              ></p>
                              <div className="text-danger">
                                {errors.below_channels_linkedIn_linkedIn_URL && (
                                  <p>
                                    {
                                      errors.below_channels_linkedIn_linkedIn_URL
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="connections">
                                Connections
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.linkedIn.connections"
                                placeholder="Enter Connections"
                                value={
                                  formData.below_channels.linkedIn.connections
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_linkedIn_connections && (
                                  <p>
                                    {errors.below_channels_linkedIn_connections}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="ygenere">
                                    Video Cost:
                                  </label>
                                  <FormattedInput
                                    label="Video Cost"
                                    name="below_channels.linkedIn.video_Cost"
                                    placeholder="Enter Connections"
                                    value={
                                      formData.below_channels.linkedIn
                                        .video_Cost
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <div className="video_cost_disp text-white"></div>
                                  <div className="text-danger">
                                    {errors.below_channels_linkedIn_video_Cost && (
                                      <p>
                                        {
                                          errors.below_channels_linkedIn_video_Cost
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.whatsapp_status && (
                        <div id="whatsappInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Whatsapp</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="whatsappUrl">
                                Whatsapp Channel URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.whatsapp.whatsapp_Channel_URL"
                                placeholder="Enter Whatsapp Channel URL"
                                value={
                                  formData.below_channels.whatsapp
                                    .whatsapp_Channel_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_whatsapp_whatsapp_Channel_URL && (
                                  <p>
                                    {
                                      errors.below_channels_whatsapp_whatsapp_Channel_URL
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="whatsappUrl">
                                Followers<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.whatsapp.followers"
                                placeholder="Enter Whatsapp Channel URL"
                                value={
                                  formData.below_channels.whatsapp.followers
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_whatsapp_followers && (
                                  <p>
                                    {errors.below_channels_whatsapp_followers}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="ygenere">
                                    Video Cost:
                                  </label>
                                  <FormattedInput
                                    label="Video Cost"
                                    name="below_channels.whatsapp.video_Cost"
                                    placeholder="Enter Whatsapp Video Cost"
                                    value={
                                      formData.below_channels.whatsapp
                                        .video_Cost
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <div className="video_cost_disp text-white"></div>
                                  <div className="text-danger">
                                    {errors.below_channels_whatsapp_video_Cost && (
                                      <p>
                                        {
                                          errors.below_channels_whatsapp_video_Cost
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.telegram_status && (
                        <div id="telegramInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Telegram</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="telegramUrl">
                                Telegram URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.telegram.telegram_URL"
                                placeholder="Enter telegram Video Cost"
                                value={
                                  formData.below_channels.telegram.telegram_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_telegram_telegram_URL && (
                                  <p>
                                    {
                                      errors.below_channels_telegram_telegram_URL
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="whatsappUrl">
                                Followers<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.telegram.followers"
                                placeholder="Enter telegram Followers"
                                value={
                                  formData.below_channels.telegram.followers
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_telegram_followers && (
                                  <p>
                                    {errors.below_channels_telegram_followers}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="ygenere">
                                    Video Cost:
                                  </label>
                                  <FormattedInput
                                    label="Video Cost"
                                    name="below_channels.telegram.video_Cost"
                                    placeholder="Enter telegram Video Cost"
                                    value={
                                      formData.below_channels.telegram
                                        .video_Cost
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <div className="video_cost_disp text-white"></div>
                                  <div className="text-danger">
                                    {errors.below_channels_telegram_video_Cost && (
                                      <p>
                                        {
                                          errors.below_channels_telegram_video_Cost
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.below_channels.threads_status && (
                        <div id="threadsInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Threads</h5>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="threadsUrl">
                                Threads URL
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.threads.threads_URL"
                                placeholder="Enter telegram Threads URL"
                                value={
                                  formData.below_channels.threads.threads_URL
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_threads_threads_URL && (
                                  <p>
                                    {errors.below_channels_threads_threads_URL}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="whatsappUrl">
                                Followers<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="below_channels.threads.followers"
                                placeholder="Enter telegram Followers"
                                value={
                                  formData.below_channels.threads.followers
                                }
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.below_channels_threads_followers && (
                                  <p>
                                    {errors.below_channels_threads_followers}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label for="ygenere">
                                    Video Cost:
                                  </label>
                                  <FormattedInput
                                    label="Video Cost"
                                    name="below_channels.threads.video_Cost"
                                    placeholder="Enter telegram Video Cost"
                                    value={
                                      formData.below_channels.threads.video_Cost
                                    }
                                    onChange={handleInputChange}
                                  />
                                  <div className="video_cost_disp text-white"></div>
                                  <div className="text-danger">
                                    {errors.below_channels_threads_video_Cost && (
                                      <p>
                                        {
                                          errors.below_channels_threads_video_Cost
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <hr />
                      <p className="mb-0 fs-12 text-muted fst-italic">
                        By registering you agree to the Influencer Act{" "}
                        <a
                          href="#"
                          className="text-primary text-decoration-underline fst-normal fw-medium"
                        >
                          Terms of Use
                        </a>
                      </p>
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-success w-100"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
