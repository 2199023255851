import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../features/auth/authSlice";
import {
  fetchCategoryData,
  fetchLanguageData
} from "../../features/commonSlice/commonSlice";
import { clientInfluencerRequest } from "../../features/clientInfluencerRequestSlice/clientInfluencerRequestSlice";

const budgetData = [
  { label: "0-5", value: "0-5" },
  { label: "10-20", value: "6-10" },
  { label: "20-30", value: "11-15" },
  { label: "30-40", value: "16-20" },
  { label: "40-50", value: "21-50" },
  { label: "50+", value: "50+" },
  { label: "100+", value: "100+" }
];
const videoTypeData = [
  { label: "Dedicated", value: 1 },
  { label: "Integrated", value: 2 },
  { label: "Conceptual", value: 3 }
];

const follower_subscribers = [
  { label: "0-5k", value: "0-5k" },
  { label: "5k-10k", value: "5k-10k" },
  { label: "10k-30k", value: "10k-30k" },
  { label: "30k-50k", value: "30k-50k" },
  { label: "50k-100k", value: "50k-100k" },
  { label: "100k-200k", value: "100k-200k" },
  { label: "200k-300k", value: "200k-300k" },
  { label: "300k-500k", value: "300k-500k" },
  { label: "500k-1M", value: "500k-1M" },
  { label: "1M-2M", value: "1M-2M" },
  { label: "2M-5M", value: "2M-5M" },
  { label: "5M-10M", value: "5M-10M" },
  { label: "10M-20M", value: "10M-20M" },
  { label: "20M-30M", value: "20M-30M" },
  { label: "30M-50M", value: "30M-50M" },
  { label: "50M-100M", value: "50M-100M" },
  { label: "100M-200M", value: "100M-200M" },
  { label: "200M-300M", value: "200M-300M" },
  { label: "300M-400M", value: "300M-400M" },
  { label: "400M-500M", value: "400M-500M" },
  { label: "500M+", value: "500M+" }
];

const EnquireInfluencersRequest = (props) => {
  const { showModal, setShowModal, decodedToken } = props;
  const dispatch = useDispatch();
  const [langList, setLangList] = useState([]);
  const [errors, setErrors] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [loginError, setLoginError] = useState("");

  const { languageData, status: commonStatus } = useSelector(
    (state) => state.influencer
  );
  const [formData, setFormData] = useState({
    product: "",
    no_of_influencer: "",
    budget: "",
    channel_data: {
      youTube_status: "",
      youTube: {
        language: [],
        budget: "",
        follower_subscriber: "",
        video_type: []
      },
      facebook_status: "",
      facebook: {
        language: [],
        budget: "",
        follower_subscriber: ""
      },
      instagram_status: "",
      instagram: {
        language: [],
        budget: "",
        follower_subscriber: ""
      },
      twitter_X_status: "",
      twitter_X: {
        language: [],
        budget: "",
        follower_subscriber: ""
      },
      linkedIn_status: "",
      linkedIn: {
        language: [],
        budget: "",
        follower_subscriber: ""
      },
      whatsapp_status: "",
      whatsapp: {
        language: [],
        budget: "",
        follower_subscriber: ""
      },
      telegram_status: "",
      telegram: {
        language: [],
        budget: "",
        follower_subscriber: ""
      },
      threads_status: "",
      threads: {
        language: [],
        budget: "",
        follower_subscriber: ""
      }
    }
  });

  const mapErrors = (errorData) => {
    const errorMap = {};
    errorData.forEach((error) => {
      if (!errorMap[error.param]) {
        const path = error.param.split(".").join("_");
        errorMap[path] = error.msg;
      }
    });
    return errorMap;
  };
  console.log("name ALLCHANNEL:", errors.channel_data_youTube_language);
  useEffect(() => {
    const errorsValue = mapErrors(errorData);
    setErrors(errorsValue);
  }, [errorData]);

  useEffect(() => {
    dispatch(fetchLanguageData());
  }, []);

  useEffect(() => {
    if (commonStatus === "succeeded") {
      setLangList(languageData);
    }
  }, [commonStatus]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      channel_data: {
        ...prevState.channel_data,
        [name]: checked
      }
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let field = newFormData;
      const keys = name.split(".");
      keys.slice(0, -1).forEach((key) => {
        if (!field[key]) {
          field[key] = {};
        }
        field = field[key];
      });
      field[keys[keys.length - 1]] = selectedOption;
      return newFormData;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let field = newFormData;
      const keys = name.split(".");
      keys.slice(0, -1).forEach((key) => {
        if (!field[key]) {
          field[key] = {};
        }
        field = field[key];
      });
      field[keys[keys.length - 1]] = value;
      return newFormData;
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const influencerData = {
        client_id: decodedToken?.UsrId,
        product: formData.product,
        no_of_influencer: formData.no_of_influencer?.label,
        budget: formData.budget,
        channel_data: {
          youTube_status: formData.channel_data.youTube_status ? "1" : "0",
          youTube: formData.channel_data.youTube,
          facebook_status: formData.channel_data.facebook_status ? "1" : "0",
          facebook: formData.channel_data.facebook,
          instagram_status: formData.channel_data.instagram_status ? "1" : "0",
          instagram: formData.channel_data.instagram,
          twitter_X_status: formData.channel_data.twitter_X_status ? "1" : "0",
          twitter_X: formData.channel_data.twitter_X,
          linkedIn_status: formData.channel_data.linkedIn_status ? "1" : "0",
          linkedIn: formData.channel_data.linkedIn,
          whatsapp_status: formData.channel_data.whatsapp_status ? "1" : "0",
          whatsapp: formData.channel_data.whatsapp,
          telegram_status: formData.channel_data.telegram_status ? "1" : "0",
          telegram: formData.channel_data.telegram,
          threads_status: formData.channel_data.threads_status ? "1" : "0",
          threads: formData.channel_data.threads
        }
      };
      dispatch(clientInfluencerRequest(influencerData))
        .unwrap()
        .then((result) => {
          if (result.status) {
            toast.success(result.message, {
              onClose: () => {
                closeModal(
                  setFormData({
                    product: "",
                    no_of_influencer: "",
                    budget: "",
                    channel_data: {
                      youTube_status: "",
                      youTube: {
                        language: [],
                        budget: "",
                        follower_subscriber: "",
                        video_type: []
                      },
                      facebook_status: "",
                      facebook: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      },
                      instagram_status: "",
                      instagram: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      },
                      twitter_X_status: "",
                      twitter_X: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      },
                      linkedIn_status: "",
                      linkedIn: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      },
                      whatsapp_status: "",
                      whatsapp: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      },
                      telegram_status: "",
                      telegram: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      },
                      threads_status: "",
                      threads: {
                        language: [],
                        budget: "",
                        follower_subscriber: ""
                      }
                    }
                  })
                );
              }
            });
          } else {
            setLoginError(result.message);
            setErrorData(result.form_err);
            toast.error(result.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            });
          }
        })
        .catch((err) => {
          setLoginError(err.message);
          setErrorData(err.form_err || []);
          toast.error(err.message);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="ModalHeader"
        size="xl"
        backdrop="static" // Prevents closing on backdrop click
        keyboard={false} // Prevents closing on ESC key press
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Influencers Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="p-0">
                    <div>
                      <>
                        <div className="row">
                          <div>
                            <h5 className="text-primary">Personal Details</h5>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="username" className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="influencer_name"
                                readOnly={true}
                                disabled={true}
                                placeholder="Please Enter Name"
                                value={decodedToken?.UsrName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="concernname"
                                className="form-label"
                              >
                                Company name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="concern_name"
                                readOnly={true}
                                disabled={true}
                                placeholder="Please Enter Name"
                                value={decodedToken?.UsrCompName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3">
                            <div>
                              <label htmlFor="product" className="form-label">
                                Product <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="product"
                                placeholder="Enter product name"
                                value={formData.product}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.product && <p>{errors.product}</p>}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <div className="mb-3">
                              <label
                                htmlFor="no_of_influencer"
                                className="form-label"
                              >
                                No of influencers required{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="no_of_influencer"
                                options={budgetData}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "no_of_influencer",
                                    selected
                                  )
                                }
                                value={formData.no_of_influencer}
                              />
                              <div className="text-danger">
                                {errors.no_of_influencer && (
                                  <p>{errors.no_of_influencer}</p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label htmlFor="budget" className="form-label">
                                Budget
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="budget"
                                placeholder="Enter budget"
                                value={formData.budget}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.budget && <p>{errors.budget}</p>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      <hr />

                      <div className="row mt-2 g-3">
                        <h5 className="text-primary">Channel details</h5>

                        <label>
                          Select Below Channels
                          {errors.channel_data && (
                            <p className="text-danger small error">
                              {errors.channel_data}
                            </p>
                          )}
                        </label>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="youTube_status"
                            checked={formData.channel_data.youTube_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="youtubeCheckbox"
                          >
                            {" "}
                            YouTube
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="facebook_status"
                            checked={formData.channel_data.facebook_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="facebookCheckbox"
                          >
                            {" "}
                            Facebook
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="instagram_status"
                            checked={formData.channel_data.instagram_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="instagramCheckbox"
                          >
                            {" "}
                            Instagram
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="twitter_X_status"
                            checked={formData.channel_data.twitter_X_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="twitterCheckbox"
                          >
                            {" "}
                            Twitter (X)
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="linkedIn_status"
                            checked={formData.channel_data.linkedIn_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="linkedinCheckbox"
                          >
                            {" "}
                            LinkedIn
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="whatsapp_status"
                            checked={formData.channel_data.whatsapp_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="whatsappCheckbox"
                          >
                            {" "}
                            Whatsapp
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="telegram_status"
                            checked={formData.channel_data.telegram_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="telegramCheckbox"
                          >
                            {" "}
                            Telegram
                          </label>
                        </div>
                        <div className="form-check col-lg-3 mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="threads_status"
                            checked={formData.channel_data.threads_status}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            for="threadsCheckbox"
                          >
                            {" "}
                            Threads
                          </label>
                        </div>
                      </div>
                      {formData.channel_data.youTube_status && (
                        <div id="youtubeInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">You Tube</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="youtubeUrl">
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.youTube.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.youTube.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.youTube.language",
                                    selected
                                  )
                                }
                              />

                              <div className="text-danger">
                                {errors.channel_data_youTube_language && (
                                  <p>
                                    {errors.channel_data_youTube_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.youTube.budget"
                                placeholder="Enter youTube URL"
                                value={formData.channel_data.youTube.budget}
                                onChange={handleInputChange}
                                required
                              />
                               <div className="text-danger">
                                {errors.channel_data_youTube_budget && (
                                  <p>
                                    {errors.channel_data_youTube_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.youTube.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.youTube.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.youTube
                                    .follower_subscriber
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_youTube_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_youTube_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Video type
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti
                                name="channel_data.youTube.video_type"
                                options={videoTypeData}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.youTube.video_type",
                                    selected
                                  )
                                }
                                value={formData.channel_data.youTube.video_type}
                              />
                               <div className="text-danger">
                                {errors.channel_data_youTube_video_type && (
                                  <p>
                                    {errors.channel_data_youTube_video_type}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.channel_data.facebook_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Facebook</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.facebook.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.facebook.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.facebook.language",
                                    selected
                                  )
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_facebook_language && (
                                  <p>
                                    {errors.channel_data_facebook_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.facebook.budget"
                                placeholder="Enter facebook URL"
                                value={formData.channel_data.facebook.budget}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.channel_data_facebook_budget && (
                                  <p>
                                    {errors.channel_data_facebook_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.facebook.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.facebook.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.facebook
                                    .follower_subscriber
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_facebook_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_facebook_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {formData.channel_data.instagram_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Instagram</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.instagram.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.instagram.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.instagram.language",
                                    selected
                                  )
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_instagram_language && (
                                  <p>
                                    {errors.channel_data_instagram_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.instagram.budget"
                                placeholder="Enter instagram URL"
                                value={formData.channel_data.instagram.budget}
                                onChange={handleInputChange}
                                required
                              />
                               <div className="text-danger">
                                {errors.channel_data_instagram_budget && (
                                  <p>
                                    {errors.channel_data_instagram_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.instagram.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.instagram.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.instagram
                                    .follower_subscriber
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_instagram_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_instagram_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.channel_data.twitter_X_status && (
                        <div id="twitter">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Twitter </h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.twitter_X.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.twitter_X.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.twitter_X.language",
                                    selected
                                  )
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_twitter_X_language && (
                                  <p>
                                    {errors.channel_data_twitter_X_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.twitter_X.budget"
                                placeholder="Enter twitter_X URL"
                                value={formData.channel_data.twitter_X.budget}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.channel_data_twitter_X_budget && (
                                  <p>
                                    {errors.channel_data_twitter_X_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.twitter_X.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.twitter_X.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.twitter_X
                                    .follower_subscriber
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_twitter_X_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_twitter_X_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {formData.channel_data.linkedIn_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">LinkedIn</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.linkedIn.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.linkedIn.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.linkedIn.language",
                                    selected
                                  )
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_linkedIn_language && (
                                  <p>
                                    {errors.channel_data_linkedIn_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.linkedIn.budget"
                                placeholder="Enter linkedIn URL"
                                value={formData.channel_data.linkedIn.budget}
                                onChange={handleInputChange}
                                required
                              />
                               <div className="text-danger">
                                {errors.channel_data_linkedIn_budget && (
                                  <p>
                                    {errors.channel_data_linkedIn_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.linkedIn.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.linkedIn.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.linkedIn
                                    .follower_subscriber
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_linkedIn_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_linkedIn_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {formData.channel_data.whatsapp_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Whatsapp</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.whatsapp.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.whatsapp.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.whatsapp.language",
                                    selected
                                  )
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_whatsapp_language && (
                                  <p>
                                    {errors.channel_data_whatsapp_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.whatsapp.budget"
                                placeholder="Enter whatsapp URL"
                                value={formData.channel_data.whatsapp.budget}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.channel_data_whatsapp_budget && (
                                  <p>
                                    {errors.channel_data_whatsapp_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.whatsapp.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.whatsapp.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.whatsapp
                                    .follower_subscriber
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_whatsapp_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_whatsapp_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {formData.channel_data.telegram_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Telegram</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.telegram.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.telegram.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.telegram.language",
                                    selected
                                  )
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_telegram_language && (
                                  <p>
                                    {errors.channel_data_telegram_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.telegram.budget"
                                placeholder="Enter telegram URL"
                                value={formData.channel_data.telegram.budget}
                                onChange={handleInputChange}
                                required
                              />
                               <div className="text-danger">
                                {errors.channel_data_telegram_budget && (
                                  <p>
                                    {errors.channel_data_telegram_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.telegram.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.telegram.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.telegram
                                    .follower_subscriber
                                }
                              />
                               <div className="text-danger">
                                {errors.channel_data_telegram_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_telegram_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {formData.channel_data.threads_status && (
                        <div id="facebookInfo">
                          <div className="row">
                            <div className="mt-2">
                              <h5 className="text-primary">Threads</h5>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label>
                                Select language
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                isMulti={true}
                                name="channel_data.threads.language"
                                options={langList?.data}
                                classNamePrefix="select"
                                value={formData.channel_data.threads.language}
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.threads.language",
                                    selected
                                  )
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_threads_language && (
                                  <p>
                                    {errors.channel_data_threads_language}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="ygenere">
                                Tentative budget
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="channel_data.threads.budget"
                                placeholder="Enter threads URL"
                                value={formData.channel_data.threads.budget}
                                onChange={handleInputChange}
                                required
                              />
                              <div className="text-danger">
                                {errors.channel_data_threads_budget && (
                                  <p>
                                    {errors.channel_data_threads_budget}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="subscribers">
                                Followers or subscriber requiremen
                                <span className="text-danger">*</span>:
                              </label>
                              <Select
                                name="channel_data.threads.follower_subscriber"
                                options={follower_subscribers}
                                classNamePrefix="select"
                                onChange={(selected) =>
                                  handleSelectChange(
                                    "channel_data.threads.follower_subscriber",
                                    selected
                                  )
                                }
                                value={
                                  formData.channel_data.threads
                                    .follower_subscriber
                                }
                              />
                              <div className="text-danger">
                                {errors.channel_data_threads_follower_subscriber && (
                                  <p>
                                    {errors.channel_data_threads_follower_subscriber}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EnquireInfluencersRequest;
