import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                    <Link className="d-inline-block auth-logo" to="/">
                    <img src="assets/images/logo-light.png" alt="" height="50" />
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Logo;
