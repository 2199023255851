import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify"; 
import { useDispatch, useSelector } from "react-redux"; 
import { fetchDmSubReq } from "../../features/clientRequstSlice/dmSubRequestSlice";
 
const ViewSubRequest = (props) => {
    const { showModal, setShowModal, selectedData } = props; 
    const { subRequestData, status } = useSelector((state) => state.subrequest || {});
    const [clientsData, setClientsData] = useState([]);
    const dispatch = useDispatch(); 
    
     
    useEffect(() => { 
            dispatch(fetchDmSubReq({ inf_req_id: selectedData?.id})); 
    }, [dispatch,selectedData]);

    useEffect(() => {
        if (status === 'succeeded') { 
            setClientsData(subRequestData?.data || []);
        }
    }, [status, subRequestData]);

    

    console.log("myStatus "+status);

    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <>
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby="ModalHeader"
                size="lg"
                backdrop="static" // Prevents closing on backdrop click
                keyboard={false} // Prevents closing on ESC key press
            >
                <Modal.Header closeButton >
                    <Modal.Title>Sub Request For REQUEST ID :{selectedData?.req_code}</Modal.Title>
                     
                </Modal.Header>
                                                        
                                                   
                <Modal.Body>
                    <div className="row justify-content-center">  
                        <div className="col-md-12  "> <br></br></div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div  >
                                <div className="card-body">
                                    <div className="p-0"> 
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                   
                                                    <th>Assigned By </th> 
                                                    <th>Assigned To  </th>
                                                    <th>Sub Req.Code  </th>
                                                    <th>Status  </th>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                             {clientsData.length > 0 ? (
                                              clientsData.map((dataActive, index) => (
                                                <tr key={index}>
                                                    <td> {dataActive.assigned_by_name}   </td>
                                                    <td> {dataActive.assigned_to_name}   </td> 
                                                    <td> {dataActive.sub_req_code}   </td>
                                                    <td> {dataActive.show_status}   </td>
                                                </tr>   
                                                   ))
                                            ) : (
                                               <tr><td colSpan="12" className="text-center">No data available</td></tr>
                                            )}
 
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                 
            </Modal>
        </>
    );
}

export default ViewSubRequest;
