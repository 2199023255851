// src/features/profile/Profile.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, updateUserProfile } from './profileSlice';

const Profile = () => {
  const dispatch = useDispatch();
  const { user, status, error } = useSelector((state) => state.profile);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.name);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserProfile({ email, name }));
  };

  return (
    <div>
      <h2>Profile</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <button type="submit" disabled={status === 'loading'}>
          {status === 'loading' ? 'Loading...' : 'Update Profile'}
        </button>
      </form>
      {status === 'failed' && <p>{error}</p>}
      {status === 'succeeded' && <p>Profile updated successfully!</p>}
    </div>
  );
};

export default Profile;
